import { type Dispatch, type FC, type SetStateAction } from "react";
import { Listbox } from "@headlessui/react";
import s from "src/components/shared/selector/styled.module.css";
import cn from "classnames";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrowDown.svg";

type SelectorProps = {
  selectedItem: string;
  setSelectedItem: Dispatch<SetStateAction<string>>;
  items: string[];
  isUpperCase?: boolean;
  listboxClassName?: string;
  variant?: "absolutePopover" | "staticPopover";
  hideSelectedOption?: boolean;
};

const Selector: FC<SelectorProps> = (props) => {
  const {
    selectedItem,
    setSelectedItem,
    items,
    isUpperCase,
    listboxClassName,
    hideSelectedOption,
    variant = "absolutePopover"
  } = props;

  return (
    <Listbox value={selectedItem} onChange={setSelectedItem}>
      <div
          className={cn(s.listbox, listboxClassName, { uppercase: isUpperCase, [s[variant]]: true, [s.absoluteListbox]: variant === 'absolutePopover', [s.staticListbox]: variant === 'staticPopover' })}
      >
      <Listbox.Button
          as="button"
          className={({ open }) =>
            cn("primaryButton", "minimal", s.listboxButton, { [s.open]: open, [s.absolutelistboxButton]: variant === 'absolutePopover',  [s.staticListboxButton]: variant === 'staticPopover' })
          }
        >
          <span className={s.listboxText}>{selectedItem}</span>
          <ArrowIcon />
        </Listbox.Button>
        <Listbox.Options
          className={({ open }) =>
            cn(s.listboxOptions, { [s.open]: open, paper: variant === "absolutePopover", [s.absoluteListboxOptions]: variant === 'absolutePopover',  [s.staticlistboxOptions]: variant === 'staticPopover' })
          }
        >
          {items.map((item) => (
            <Listbox.Option
              key={item}
              value={item}
              className={({ selected, active }) =>
                cn(s.listboxOption, { [s.active]: selected || active, [s.absoluteListboxOption]: variant === 'absolutePopover',  [s.staticListboxOption]: variant === 'staticPopover' })
              }
              hidden={hideSelectedOption && item === selectedItem}
            >
              {item}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default Selector;

import {type ServiceType} from "src/types";
import {ReactComponent as AnchorDown} from "src/assets/icons/anchorDown.svg";
import cn from "classnames";
import {type FC} from "react";
import {type Row} from "@tanstack/react-table";
import {TagsIconsCell} from "src/components/main/table-columns";
import {Disclosure, Transition} from "@headlessui/react";
import s from 'src/components/main/row-list/mobile-row-accordion/styled.module.css'

type MobileRowAccordionProps = {
  row: Row<ServiceType>;
}

const MobileRowAccordion: FC<MobileRowAccordionProps> = (props) => {
  const {row} = props

  const openServicePage = (link: string) => {
    window.open('https://clients.anqor.is/portal/services', '_blank')
  }

  return (
    <Disclosure>
      {({open}) => (
        <>
          <Disclosure.Button
            as="button"
            className={cn(s.accordionButton, s.s, {
              [s.open]: open
            })}
          >
            <div className={s.buttonContent} onClick={() => openServicePage(row.original.link)}>
              <div className={s.icons}>
                <TagsIconsCell tags={row.original.tags}/>
              </div>

              <div className={s.mainInfo}>
                <p>{row.original.name}</p>
                <p className={s.tagNames}>
                  {
                    row.original.tags.map(({name}) => (
                      <span key={name}>{name}</span>
                    ))
                  }
                </p>
              </div>
            </div>
            <AnchorDown className={cn(s.anchor, {'verticalMirror': open})}/>
          </Disclosure.Button>
          <Transition
            show={open}
            enter={s.enter}
            enterFrom={s.enterFrom}
            enterTo={s.enterTo}
            leave={s.leave}
            leaveFrom={s.leaveFrom}
            leaveTo={s.leaveTo}
          >
            <Disclosure.Panel className={cn(s.accordionPanel, {[s.open]: open})}>
              <div className={s.rows}>
                <div className={s.row}>
                  <div>
                    <p>Supply</p>
                    <p>{row.original.supply ?? '-'}</p>
                  </div>

                  <div>
                    <p>Price</p>
                    <p><b>${row.original.price ?? '-'}</b></p>
                  </div>

                  <div>
                    <p>DR</p>
                    <p>{row.original.dr ?? '-'}</p>
                  </div>

                  <div>
                    <p>RD</p>
                    <p>{row.original.rd ?? '-'}</p>
                  </div>
                </div>            
                <div>
                  <p>Terms</p>
                  <p dangerouslySetInnerHTML={{ __html: row.original.terms ?? '-' }}></p>
                </div>

                <div className={s.buttonGroup}>

                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

export default MobileRowAccordion;

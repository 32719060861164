// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_container__CamDn {
  cursor: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid transparent;
  transition: border-color 0.2s ease-in-out;

  &:hover {
    gap: 8px;
    border-color: lightgray;
    transition: border-color 0.2s ease-in-out;

    & .styled_additionalBlock__NX6At {
      max-width: 100px;
    }
  }
}

.styled_title__UvAtd {
}

.styled_copyButton__zGpKZ {
  pointer-events: all;
  display: grid;
  place-items: center;
}

.styled_additionalBlock__NX6At {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 0;
  transition: max-width 1s ease 0s;
}

.styled_text__PxxfO {
  font-size: 12px;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes styled_slideOut__rUwmp {
  from {
    width: auto;
  }
  to {
    width: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/copy-chip/styled.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,6BAA6B;EAC7B,yCAAyC;;EAEzC;IACE,QAAQ;IACR,uBAAuB;IACvB,yCAAyC;;IAEzC;MACE,gBAAgB;IAClB;EACF;AACF;;AAEA;AACA;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,YAAY;EACZ,gCAAgC;AAClC;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,QAAQ;EACV;AACF","sourcesContent":[".container {\n  cursor: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n  border: 1px solid transparent;\n  transition: border-color 0.2s ease-in-out;\n\n  &:hover {\n    gap: 8px;\n    border-color: lightgray;\n    transition: border-color 0.2s ease-in-out;\n\n    & .additionalBlock {\n      max-width: 100px;\n    }\n  }\n}\n\n.title {\n}\n\n.copyButton {\n  pointer-events: all;\n  display: grid;\n  place-items: center;\n}\n\n.additionalBlock {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  max-width: 0;\n  transition: max-width 1s ease 0s;\n}\n\n.text {\n  font-size: 12px;\n  font-weight: normal;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n@keyframes slideOut {\n  from {\n    width: auto;\n  }\n  to {\n    width: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styled_container__CamDn`,
	"additionalBlock": `styled_additionalBlock__NX6At`,
	"title": `styled_title__UvAtd`,
	"copyButton": `styled_copyButton__zGpKZ`,
	"text": `styled_text__PxxfO`,
	"slideOut": `styled_slideOut__rUwmp`
};
export default ___CSS_LOADER_EXPORT___;

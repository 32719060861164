import { type FC, type ReactNode } from "react";

import Modal from "react-modal";
import styles from "./MyModal.module.css";

Modal.setAppElement("#root");

interface MyModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  children: ReactNode;
}

const MyModal: FC<MyModalProps> = ({ isOpen, onRequestClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="My Modal"
      className={styles.modal}
      bodyOpenClassName={styles.bodyOpen}
    >
      {children}
      <button onClick={onRequestClose} className={styles.closeButton}>
        Close
      </button>
    </Modal>
  );
};

export default MyModal;

import Logo from "src/components/shared/logo";
import s from "./MyModal.module.css";

const SubmitYourWebsite = () => (
  <div className={s.inside}>
    <div>
      <div className={s.wrapper}>
        <h3 className={s.title}>Submit Your Website</h3>
        <Logo />
      </div>
      <br />
      <section>
        <p>
          We appreciate your enthusiasm to join our platform! Unfortunately, due to a significant
          backlog, we are temporarily pausing new submissions. We&apos;re working hard to review all
          current submissions and hope to open up for new submissions soon. Thank you for your
          understanding and patience.
        </p>
      </section>
    </div>
  </div>
);

export default SubmitYourWebsite;

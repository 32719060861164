import logo from "src/assets/images/anqor.png";
import logoLight from "src/assets/images/anqor-white.png";

import { useTheme } from "src/theme";
import s from "./styled.module.css";

const Logo = () => {
  const { isDarkTheme } = useTheme();

  return (
    <a href="#" className={s.logo}>
      {isDarkTheme ? (
        <img src={logoLight} alt="logo" width={200} />
      ) : (
        <img src={logo} alt="logo" width={200} />
      )}
    </a>
  );
};

export default Logo;

export const LANGUAGES = ["English"];

export const BEFORE_HEADER_ITEMS = {
  "": ""
};

export const BEFORE_HEADER_SETTINGS_LINKS = {
  "": ""
};

export const POLICIES_LINKS = {
  terms: "Terms",
  privacyPolicy: "Privacy Policy"
};

export const HELP_RESOURCES_LINKS = {
  about: "About",
  faq: "FAQ",
  submitYourWebsite: "Submit Your Website"
};

export const HEADER_LINKS = {
  "/": "Dashboard"
};

export const COMMUNITY_LINKS = {
  "/path1": "Discord",
  "/path2": "Twitter",
  "/path3": "Telegram"
};

export const RESOURCES_LINKS = {
  "/path1": "Whitepaper",
  "/path2": "Audits",
  "/path3": "Events"
};

import {ReactComponent as SearchIcon} from "src/assets/icons/search.svg";
import s from 'src/components/main/search/styled.module.css'
import type {DetailedHTMLProps, FC, InputHTMLAttributes} from "react";

type SearchProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;


const Search: FC<SearchProps> = (props) => {
  return (
    <div className={s.inputContainer}>
      <SearchIcon width={30} height={30}/>
      <input {...props} className={s.input} />
    </div>
  );
};

export default Search;

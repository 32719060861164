// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-size: inherit;
}

&.styled_disclosure__e99IO {
  display: block;
  width: 100%;
}

& .styled_disclosurePanel__9fjTo {
  cursor: pointer;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &.styled_open__qZkkp {
    height: auto;
    transition: all 0.2s ease-in-out;
  }
}

& .styled_disclosureButton__ZG4Jd {
  width: 100%;
  justify-content: space-between;
  padding: 5px 5px 5px 0;

  font-weight: inherit;
  display: flex;
  align-items: center;
  text-transform: inherit;

  & svg {
    width: 20px;
    height: 18px;
  }

  &.styled_open__qZkkp svg {
    transform: rotate(180deg);
    transition: var(--transition);
  }

  &:hover svg {
    color: var(--primary-color);
    transition: var(--transition);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/disclosure/styled.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,SAAS;EACT,gBAAgB;EAChB,gCAAgC;;EAEhC;IACE,YAAY;IACZ,gCAAgC;EAClC;AACF;;AAEA;EACE,WAAW;EACX,8BAA8B;EAC9B,sBAAsB;;EAEtB,oBAAoB;EACpB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,6BAA6B;EAC/B;;EAEA;IACE,2BAA2B;IAC3B,6BAA6B;EAC/B;AACF","sourcesContent":["* {\n  font-size: inherit;\n}\n\n&.disclosure {\n  display: block;\n  width: 100%;\n}\n\n& .disclosurePanel {\n  cursor: pointer;\n  width: 100%;\n  height: 0;\n  overflow: hidden;\n  transition: all 0.2s ease-in-out;\n\n  &.open {\n    height: auto;\n    transition: all 0.2s ease-in-out;\n  }\n}\n\n& .disclosureButton {\n  width: 100%;\n  justify-content: space-between;\n  padding: 5px 5px 5px 0;\n\n  font-weight: inherit;\n  display: flex;\n  align-items: center;\n  text-transform: inherit;\n\n  & svg {\n    width: 20px;\n    height: 18px;\n  }\n\n  &.open svg {\n    transform: rotate(180deg);\n    transition: var(--transition);\n  }\n\n  &:hover svg {\n    color: var(--primary-color);\n    transition: var(--transition);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclosure": `styled_disclosure__e99IO`,
	"disclosurePanel": `styled_disclosurePanel__9fjTo`,
	"open": `styled_open__qZkkp`,
	"disclosureButton": `styled_disclosureButton__ZG4Jd`
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from "react";

import Header from "src/components/header";
import Main from "src/components/main";
import Footer from "src/components/footer";
import MyModal from "src/components/MyModal";
import AboutUs from "src/components/AboutUs";
import Privacy from "src/components/Privacy";
import Terms from "src/components/Terms";
import Faq from "src/components/Faq";
import SubmitYourWebsite from "src/components/SubmitYourWebsite";
import Updates from "src/components/Updates"; 

import s from "./App.module.css";
import type { ReactElement } from "react";

const App = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactElement | null>(null);
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);

  useEffect(() => {
    const hasSeenUpdates = localStorage.getItem('hasSeenUpdates');
    if (!hasSeenUpdates) {
      openModalWithContent(<Updates onClose={() => setModalOpen(false)} />);
      localStorage.setItem('hasSeenUpdates', 'true');
    }
  }, []);

  const openModalWithContent = (contentComponent: ReactElement) => {
    setModalContent(contentComponent);
    setModalOpen(true);
  };

  return (
    <div className={s.page}>
      <Header
        openAboutUsModal={() => openModalWithContent(<AboutUs />)}
        openTermsModal={() => openModalWithContent(<Terms />)}
        openPrivacyModal={() => openModalWithContent(<Privacy />)}
        openSubmitYourWebsiteModal={() => openModalWithContent(<SubmitYourWebsite />)}
        openFaqModal={() => openModalWithContent(<Faq />)}
      />
      <div>
        <MyModal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)}>
          {modalContent}
        </MyModal>
      </div>
      <Main />
      <Footer
        openAboutUsModal={() => openModalWithContent(<AboutUs />)}
        openTermsModal={() => openModalWithContent(<Terms />)}
        openPrivacyModal={() => openModalWithContent(<Privacy />)}
        openSubmitYourWebsiteModal={() => openModalWithContent(<SubmitYourWebsite />)}
        openFaqModal={() => openModalWithContent(<Faq />)}
      />

      {showUpdatesModal && (
        <Updates onClose={() => setShowUpdatesModal(false)} />
      )}
    </div>
  );
};

export default App;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: var(--font-family);
  color: var(--primary-text-color);
  background-color: var(--page-background-color);
}

a {
  &:is(:hover, :focus) {
    color: var(--primary-color);
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}

button {
  cursor: pointer;
}

h3 {
  font-size: 18px;
}

.uppercase {
  text-transform: uppercase;
}

.paper {
  border: var(--border);
  padding: 4px;
  box-shadow: 5px 5px 0 var(--color-dusty-gray);
  background: var(--color-white);
}

.font-weight-normal {
  font-weight: normal;
}

.container {
  max-width: var(--size-container);
  margin: 0 auto;
  padding: 0 20px;
}

.primaryButton {
  background: transparent;

  &:not(.minimal) {
    border: var(--border);
  }

  &:is(.active, :hover) {
    background: var(--primary-color-light);
    color: var(--primary-color);
    transition: var(--transition);
  }
}

.relative {
  position: relative;
}

.verticalMirror {
  transform: rotate(180deg);
}

.capitalize {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

.w-content {
  width: -moz-fit-content;
  width: fit-content;
}

.updateContent {
  display: flex;
  align-items: center;
}

.updateContent p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/globals.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,gCAAgC;EAChC,8CAA8C;AAChD;;AAEA;EACE;IACE,2BAA2B;IAC3B,wDAAwD;EAC1D;AACF;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,6CAA6C;EAC7C,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gCAAgC;EAChC,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,uBAAuB;;EAEvB;IACE,qBAAqB;EACvB;;EAEA;IACE,sCAAsC;IACtC,2BAA2B;IAC3B,6BAA6B;EAC/B;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,SAAS;AACX","sourcesContent":["body {\n  font-family: var(--font-family);\n  color: var(--primary-text-color);\n  background-color: var(--page-background-color);\n}\n\na {\n  &:is(:hover, :focus) {\n    color: var(--primary-color);\n    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;\n  }\n}\n\nbutton {\n  cursor: pointer;\n}\n\nh3 {\n  font-size: 18px;\n}\n\n.uppercase {\n  text-transform: uppercase;\n}\n\n.paper {\n  border: var(--border);\n  padding: 4px;\n  box-shadow: 5px 5px 0 var(--color-dusty-gray);\n  background: var(--color-white);\n}\n\n.font-weight-normal {\n  font-weight: normal;\n}\n\n.container {\n  max-width: var(--size-container);\n  margin: 0 auto;\n  padding: 0 20px;\n}\n\n.primaryButton {\n  background: transparent;\n\n  &:not(.minimal) {\n    border: var(--border);\n  }\n\n  &:is(.active, :hover) {\n    background: var(--primary-color-light);\n    color: var(--primary-color);\n    transition: var(--transition);\n  }\n}\n\n.relative {\n  position: relative;\n}\n\n.verticalMirror {\n  transform: rotate(180deg);\n}\n\n.capitalize {\n  text-transform: capitalize;\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}\n\n.w-content {\n  width: fit-content;\n}\n\n.updateContent {\n  display: flex;\n  align-items: center;\n}\n\n.updateContent p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

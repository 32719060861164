import s from "src/components/header/styled.module.css";
import cn from "classnames";
import Selector from "src/components/shared/selector";
import { ReactComponent as SunIcon } from "src/assets/icons/sun.svg";
import { ReactComponent as MoonIcon } from "src/assets/icons/moon.svg";
import { useState } from "react";
import {
  BEFORE_HEADER_ITEMS,
  BEFORE_HEADER_SETTINGS_LINKS,
  LANGUAGES
} from "src/components/header/vars";
import { useTheme } from "src/theme";

const BeforeHeader = () => {
  const [language, setLanguage] = useState("English");
  const { isDarkTheme, toggleTheme } = useTheme();

  return (
    <div className={s.beforeHeader}>
      <div className={cn(s.beforeHeader__content, "container")}>
        <div className={s.beforeHeader__content_item}>
          {Object.entries(BEFORE_HEADER_ITEMS).map(([key, value]) => (
            <p key={key} className={s.beforeHeader__item}>
              {key} {value}
            </p>
          ))}
        </div>

        <div className={s.beforeHeader__content_item}>
          {Object.entries(BEFORE_HEADER_SETTINGS_LINKS).map(([link, title], index) => (
            <div key={index}>
              <a href={link} key={link} className={s.settingLink}>
                {title}
              </a>
            </div>
          ))}

          <button className={cn("primaryButton", "minimal", s.themeButton)} onClick={toggleTheme}>
            {isDarkTheme ? (
              <MoonIcon width={20} height={18} className={cn(s.icon, s.moonIcon)} />
            ) : (
              <SunIcon width={20} height={18} className={s.icon} />
            )}
          </button>
          <span className={s.divider} />
          <Selector
            selectedItem={language}
            setSelectedItem={setLanguage}
            items={LANGUAGES}
            isUpperCase
            listboxClassName={s.listbox}
          />
        </div>
      </div>
    </div>
  );
};

export default BeforeHeader;

import Logo from "src/components/shared/logo";
import s from "./MyModal.module.css";

const Terms = () => (
  <div className={s.inside}>
    <div>
      <div className={s.wrapper}>
        <h3 className={s.title}>Terms of Service</h3>
        <Logo />
      </div>
      <p>
        Welcome to anqor.is. We match websites with backlink services and offer backlink services.
        In order to accomplish this, we have created and manage online certain website applications
        (“App” or “Apps”) and related support and hosting services (the “Service” or “Services”).
      </p>
      <p>
        Anqor.is (“Company”, “we” or “us”) offers various services to you through our web site
        (anqor.is), and our application on the web and on mobile devices, all of which are
        conditioned on your agreement to adhere to the following Terms of Service without
        modification of any kind. Your use of the Service and/or your registration with us
        constitutes your agreement to these Terms of Service. These Terms of Service are subject to
        change at any time, without prior notice. Any changes that are made to these Terms of
        Service will not apply retroactively and will not apply to disputes or events occurring
        before the change is published. You are responsible for reviewing these Terms of Service on
        a regular basis. These Terms of Service apply to all visitors and all who access our web
        site or Services.
      </p>
      <p>
        <b>
          PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION. THIS
          AGREEMENT CONTAINS A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER
          PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES,
          RATHER THAN JURY TRIALS OR CLASS ACTIONS.
        </b>
      </p>
      <p>
        We respect the privacy and security of our Users. You understand that by using our Services,
        you give consent to the collection, use and disclosure of our personally identifiable
        information as well as any non-personally identifiable information, as described in more
        detail in our Privacy Policy (located at: http://anqor.is). You affirm that you are more
        than 18 years of age and are fully able and competent to enter into the terms, conditions,
        obligations, affirmations, representations, and warranties set forth in these Terms of
        Service, and to abide by and comply with these Terms of Service. If you are under 18 years
        of age, then please do not use our website or our Service. By connecting to us with a
        third-party service (e.g., Facebook or Twitter or LinkedIn), you give us permission to
        access and use your information from that service as permitted by that third-party service,
        and to store your log-in credentials for that third-party service. The Service is not
        available to any User who has been removed from the Service by us.
      </p>
      <p>
        1. Services
        <br />
        <br />
        We offer backlink services – we sell link placements to client’s websites.
      </p>
      <p>
        2. Registration
        <br />
        <br />
        By registering and/or participating in this Service, you agree and represent as follows: 1.
        You are of legal age and are otherwise capable of forming a legally binding contract; 2. All
        information you submit to us or in connection with a Service is accurate and complete and
        that you will maintain and promptly update any profile supplied to us to ensure accuracy at
        all times; 3. You agree to be contacted via Email, SMS and text messaging by us, including
        push notifications, and by third parties if relevant, regarding our Services, our website,
        third party notifications and/or advertisements, and requests to rate our website; 4. You
        hereby grant us permission to email or display your profile and such other information as
        may be supplied by you on or from our website as we shall deem advisable in its sole
        determination in connection with the Service or for marketing purposes thereof; 5. By using
        the Service, you are granting us permission to access your account and those messages, data,
        information, text, graphics, audio, video or other material (“Materials”)
        posted/uploaded/transmitted to or through the Service using your account, solely in
        connection with the provision of Services.
      </p>
      <p>
        3. Responsibility For User Content
        <br />
        <br />
        We respect the rights of third party creators and content owners and expect that you will do
        the same. Given the nature of the Service and the volume of information submitted, we cannot
        and do not monitor all of the Materials posted or transmitted by you and other third-party
        information providers via the Service, including, without limitation, any Materials posted
        via the Service. You expressly agree that we will not be liable for Materials. We reserve
        the right, but are not obligated, to remove content from the Service for any reason,
        including content that we believe violates these Terms or our Acceptable Use Policy below.
      </p>
      <p>
        4. Acceptable Use Policy
        <br />
        <br />
        The following is a partial list of the kinds of activities that are prohibited on or through
        the Service: (a) submitting Materials that are patently offensive to the online community,
        such as content that promotes racism, bigotry, hatred or physical harm of any kind against
        any group or individual; (b) engaging in activities or submitting Materials that could be
        harmful to minors; (c) engaging in activity or submitting Materials that harasses or
        advocates harassment of another person; (d) engaging in activity that involves the
        transmission of &quot;junk mail&quot; or unsolicited mass mailing or &quot;spam&quot; or
        harvesting or otherwise collecting personally identifiable information about Service users,
        including names, phone numbers, addresses, email addresses, (collectively, &quot;User
        Data&quot;) without their consent; (e) engaging in activity, or submitting Materials, or
        promoting information that is false, misleading or promotes illegal activities or conduct
        that is abusive, threatening, obscene, defamatory or libelous; (f) submitting Materials that
        contain restricted or password only access pages, or hidden pages or images; (g) submitting
        Materials that displays pornographic or sexually explicit material of any kind; (h)
        submitting Materials that provide instructional information about illegal activities such as
        making or buying illegal weapons, violating someone&apos;s privacy, or providing or creating
        computer viruses; (i) submitting Materials that contain viruses, Trojan horses, worms, or
        any other similar forms of malware, (j) engaging in activities or submitting Materials that
        solicit passwords or personally identifiable information for unlawful purposes from other
        users; (k) engaging in unauthorized commercial activities and/or sales without our prior
        written consent such as advertising, solicitations, contests, sweepstakes, barter, and
        pyramid schemes; (l) using any robot, spider, other automatic device, or manual process to
        monitor, copy, or &quot;scrape&quot; web pages or the content contained in the website or
        for any other unauthorized purpose without our prior written consent; (m) using any device,
        software, or routine to interfere or attempt to interfere with the proper working of the
        Service; (n) decompiling, reverse engineering, or disassembling the software or attempting
        to do so; or (o) taking any action that imposes an unreasonable or disproportionately large
        load on the Service or our hardware and software infrastructure or that of any of its
        Licensors or Suppliers. In addition, you covenant and represent that you will not use the
        Service for any purpose other than those that are personal, nor will you use this Service in
        violation of the law or these Terms of Service.
      </p>
      <p>
        5. Forums
        <br />
        <br />
        The Service may offer forums, blogs, comments areas, bulletin boards and chat rooms
        (collectively, &quot;Forums&quot;) that are intended to provide users 18 years of age and
        older an interesting and stimulating forum in which they can express their opinions and
        share their ideas. We do not endorse the accuracy or reliability of any advice, opinion,
        statement or information posted on these Forums. Please use your best judgment, and be
        respectful of other individuals using these Forums. Do not use vulgar, abusive or hateful
        language. Uploading copyrighted or other proprietary material of any kind on the Service
        without the express permission of the owner of that material is prohibited and may result in
        civil and/or criminal liability. Any information you disclose when posting a message in
        these Forums may become public. You should not include any information in your posting that
        you do not want other parties to see or use and you hereby agree that you will not hold us
        responsible for any third party&apos;s use of information contained in such posting. To
        maintain a positive, creative environment in which users may share and display Materials, we
        ask that you only use the Service in a manner that is consistent with our Acceptable Use
        Policy. You agree not to use user names or Forum titles that are offensive, obscene, or
        harassing to others. We reserve the right to require you to change your user name or the
        title of your Forum at any time and for any reason in our sole discretion and/or change it
        ourselves.
      </p>
      <p>
        6. Use of Third Party Services
        <br />
        <br />
        As a part of our Service, we may offer links to web sites operated by various third parties
        and are not responsible or liable for any acts or omissions created or performed by these
        third parties. We provide such links for your convenience and reference only. We do not
        operate or control in any way any information, software, products or services available on
        such web sites. Our inclusion of a link to a web site does not imply any endorsement of the
        services or the site, its contents, or its sponsoring organization.
      </p>
      <p>
        7. Representations and Warranties and Indemnification
        <br />
        <br />
        You hereby represent and warrant that: (a) you have all necessary authority, rights and
        permissions to submit the Materials and grant the licenses described in these Terms of
        Service, (b) the Materials are accurate, current and complete, (c) the Materials and your
        use of the Service shall not infringe or misappropriate any copyright, trademark, trade
        secret, patent, or other intellectual property right of any third party or violate any other
        rights of third party, including, without limitation, any rights of privacy or publicity or
        any contractual rights, (d) the Materials and your use of the Service shall not violate any
        applicable law or regulation or cause injury to any person; (e) your use of the Service
        shall not violate any agreements between you and a third party. You agree to indemnify,
        defend and hold harmless Company, and its officers, directors, employees, agents, and
        contractors from and against any and all claims, costs, demands, damages, liabilities, or
        expenses, including, without limitation, reasonable attorneys&apos; fees, arising from or
        related to: (a) the Materials you provide or approve for publication, (b) your use of the
        Service, (c) your breach of these Terms of Service, or (d) any actual, prospective,
        completed or terminated service between you and a third party.
      </p>
      <p>
        8. Disclaimers and Limitations
        <br />
        <br />
        Errors in the information contained in our Service sometimes occur. Moreover, any rating or
        positioning of software or other products/services made in connection with our Service is in
        our sole discretion and does not represent actual user votes. Furthermore, any comments or
        questions on the website may come from our staff for promotional and support reasons. We
        cannot guarantee that any of the information on the website is correct. Please use this site
        at your own risk. In addition, we may make changes and improvements to the information
        provided herein at any time. THE SERVICE AND THE INFORMATION, SOFTWARE, PRODUCTS AND
        SERVICES ASSOCIATED WITH IT ARE PROVIDED &quot;AS IS.&quot; WE AND/OR OUR SUPPLIERS,
        PARTNERS AND AFFILIATES DISCLAIM ANY WARRANTY OF ANY KIND, WHETHER EXPRESS OR IMPLIED, AS TO
        ANY MATTER WHATSOEVER RELATING TO THE SERVICE AND ANY INFORMATION, SOFTWARE, PRODUCTS AND
        SERVICES PROVIDED HEREIN, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT. USE OF OUR
        SERVICE IS AT YOUR OWN RISK. WE AND/OR OUR SUPPLIERS, ARE NOT LIABLE FOR ANY DIRECT,
        INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR OTHER INJURY ARISING OUT
        OF OR IN ANY WAY CONNECTED WITH THE USE OF THE SERVICE OR WITH THE DELAY OR INABILITY TO USE
        THE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES OBTAINED THROUGH US,
        OR OTHERWISE ARISING OUT OF THE USE OF THE SERVICE, WHETHER RESULTING IN WHOLE OR IN PART,
        FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN
        IF WE AND/OR OUR SUPPLIERS HAD BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. SOME
        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY
        NOT APPLY TO YOU.
      </p>
      <p>
        <b>LIMITATION OF LIABILITY </b>
        <br />
        <br />
        <b>
          IN NO EVENT SHALL WE OR OUR SUPPLIERS BE LIABLE FOR LOST PROFITS OR ANY SPECIAL,
          INCIDENTAL OR CONSEQUENTIAL DAMAGES (HOWEVER ARISING, INCLUDING NEGLIGENCE) ARISING OUT OF
          OR IN CONNECTION WITH THIS AGREEMENT. OUR LIABILITY, AND THE LIABILITY OF OUR SUPPLIERS
          AND AFFILIATES, TO YOU OR ANY THIRD PARTIES IN ANY CIRCUMSTANCE, SHALL NOT EXCEED THE
          TOTAL SUM OF $100.00. THE LIMITATIONS OF LIABILITY SET FORTH ABOVE ARE FUNDAMENTAL
          ELEMENTS OF THE CONTRACT BETWEEN US AND YOU. Some states do not allow the limitation of
          liability, so the foregoing limitation may not always apply.
        </b>
        <br />
        <br />
        ERRORS AND DELAYS
        <br />
        <br />
        We are not responsible for any errors or delays caused by an incorrect e-mail address
        provided by you or other technical problems beyond our reasonable control.
      </p>
      <p>
        9. Termination
        <br />
        <br />
        We reserve the right in its sole discretion, and without any prior notice, to terminate your
        access to the Service for any reason, including your breach of these Terms of Service, the
        terms and conditions of any service for which you may have registered, or a violation of the
        rights of another user or the law. You may unsubscribe from any further communication from
        us at any time by delivering a written notice addressed to hello@anqor.is. You shall be
        responsible for ensuring delivery of the notice to us. You may also unsubscribe by clicking
        the Email Preferences link or the opt-out link in any of our emails. We shall not be
        obligated to store any data or files for more than ninety (90) days after termination of
        your access to the Service. We reserve the right with or without notice to you at any time
        to change, modify or discontinue any service or a portion or attribute thereof, or the
        offering of any information, good, content, product or service. We shall have no liability
        to you or any third party should we modify or discontinue any service or an aspect thereof.
      </p>
      <p>
        10. Dispute Resolution
        <br />
        <br />
        Any claim or controversy arising out of or relating to the use of our Service, to the goods
        or services provided by us, or to any acts or omissions of other users for which you may
        contend we are liable, including but not limited to any claim or controversy
        (&quot;Dispute&quot;), shall be finally, and exclusively, settled by arbitration in Nevis,
        from which arbitration there shall be no appeal. The arbitration shall be held before one
        arbitrator. The arbitrator shall be selected pursuant to the AAA rules. The arbitrator shall
        apply the substantive law of the nation of Nevis, except that the interpretation and
        enforcement of this arbitration provision shall be governed by the U.S. Federal Arbitration
        Act. To begin the arbitration process, a party must make a written demand therefore. Each
        part shall bear its own costs and attorneys’ fees. Any judgment upon the award rendered by
        the arbitrators may be entered in any court of competent jurisdiction. The arbitrator shall
        not have the power to award damages in connection with any Dispute in excess of actual
        compensatory damages and shall not multiply actual damages or award consequential, punitive
        or exemplary damages, and each party irrevocably waives any claim thereto. The agreement to
        arbitrate shall not be construed as an agreement to the joinder or consolidation of
        arbitration under this Agreement with arbitration of disputes or claims of any non-party,
        regardless of the nature of the issues or disputes involved. THIS AGREEMENT PROVIDES THAT
        ALL DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING ARBITRATION. YOU THUS GIVE UP
        YOUR RIGHT TO GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS. YOU ALSO GIVE UP YOUR RIGHT TO
        PARTICIPATE IN OR BRING CLASS ACTIONS. YOU ACKNOWLEDGE AND AGREE THAT YOU AND WE ARE EACH
        WAIVING THE RIGHT TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
        ACTION OR REPRESENTATIVE PROCEEDING. Further, unless both you and we otherwise agree, the
        arbitrator may not consolidate more than one person&apos;s claims, and may not otherwise
        preside over any form of any class or representative proceeding. YOUR RIGHTS WILL BE
        DETERMINED BY A NEUTRAL ARBITRATOR AND NOT A JUDGE OR JURY.
      </p>
      <p>
        11. Parental Control Protections
        <br />
        <br />
        Pursuant to 47 U.S.C. Section 230 (d), as amended, we hereby notify you that there are
        commercially available parental control protections (such as computer hardware, software or
        filtering services) that may assist you in limiting access to material that is harmful to
        minors. Information identifying current providers of such protections is available at
        http://internet-filter-review.toptenreviews.com.
      </p>
      <p>
        12. Class Action/Jury Trial Waiver
        <br />
        <br />
        WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY HAVE OBTAINED OR USED
        THE SERVICE FOR PERSONAL, COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE
        PARTIES’ INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS
        ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE
        PROCEEDING. THIS WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE
        ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY ENTERING
        INTO THIS AGREEMENT, YOU AND WE ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
        PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER
        REPRESENTATIVE PROCEEDING OF ANY KIND.
      </p>
      <p>
        13. General
        <br />
        <br />
        You agree that: (i) the Service shall be deemed solely based in Nevis; and (ii) this Service
        shall be deemed a passive web site and service that does not give rise to personal
        jurisdiction over us, either specific or general, in jurisdictions other than Nevis. These
        Terms of Service shall be governed by the internal substantive laws of the nation of Nevis,
        without respect to its conflict of laws principles. The application of the United Nations
        Convention on Contracts for the International Sale of Goods is expressly excluded. These
        Terms of Service, together with the Privacy Policy (located at: https://anqor.is) and any
        other legal notices published by us on the Service, shall constitute the entire agreement
        between you and us concerning this Service and supersedes any prior written or oral
        representations. If any provision of these Terms of Service is deemed invalid by a court of
        competent jurisdiction, the invalidity of such provision shall not affect the validity of
        the remaining provisions of these Terms of Service, which shall remain in full force and
        effect. No waiver of any term of this these Terms of Service shall be deemed a further or
        continuing waiver of such term or any other term, and our failure to assert any right or
        provision under these Terms of Service shall not constitute a waiver of such right or
        provision. YOU AND WE AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO THIS
        SERVICE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH
        CAUSE OF ACTION IS PERMANENTLY BARRED. These Terms of Service may not be transferred or
        assigned by you, but may be assigned by us without restriction. Any attempted transfer or
        assignment in violation hereof shall be null and void. A printed version of these Terms of
        Service and of any notice given in electronic form shall be admissible in judicial or
        administrative proceedings based upon or relating to this agreement to the same extent and
        subject to the same conditions as other business documents and records originally generated
        and maintained in printed form. All rights not expressly granted herein are reserved to us.
      </p>
      <p>
        14. Copyright and Trade Mark Notices
        <br />
        <br />
        All contents of the Service are copyrighted © 2023 anqor.is. All rights reserved. Other
        product and company names may be trademarks or service marks of their respective owners.
        <b>
          {" "}
          IF YOU DO NOT AGREE TO THE TERMS STATED ABOVE OR TO ANY CHANGES MADE IN THESE TERMS,
          PLEASE EXIT THIS WEBSITE AND SERVICE IMMEDIATELY.
        </b>
      </p>
      <br />
      Updated on October 6, 2023
    </div>
  </div>
);

export default Terms;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_settings__hzr4Q {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
}

.styled_settingsLine__TqgLM {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
}

.styled_tabs__O8Yjc {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 10px;
  row-gap: 20px;
}

.styled_tab__XrzzQ {
  cursor: pointer;
  font-weight: 700;
  border: var(--border);
  box-shadow: var(--box-shadow-small);
  text-transform: uppercase;
  padding: 5px 10px;

  &:hover {
    background-color: var(--primary-color)!important;
    color: var(--button-text-color)!important;
  }

  &:is(:focus, .styled_active__fKXuP) {
    color: var(--color-white) !important;
    background: var(--primary-color) !important;
  }
}

.styled_sortFilterBlock__73pSS {
  display: flex;
  align-items: center;
  gap: 20px;
}

.styled_availableFilter__pqhub {
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;

  & input {
    accent-color: var(--primary-color);
    width: 20px;
    height: 20px;
    border-radius: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/main/settings/styled.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;;EAEnB;IACE,sBAAsB;IACtB,2BAA2B;IAC3B,uBAAuB;IACvB,SAAS;EACX;AACF;;AAEA;EACE,oBAAoB;EACpB,eAAe;EACf,mBAAmB;EACnB,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,qBAAqB;EACrB,mCAAmC;EACnC,yBAAyB;EACzB,iBAAiB;;EAEjB;IACE,gDAAgD;IAChD,yCAAyC;EAC3C;;EAEA;IACE,oCAAoC;IACpC,2CAA2C;EAC7C;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,SAAS;;EAET;IACE,kCAAkC;IAClC,WAAW;IACX,YAAY;IACZ,gBAAgB;EAClB;AACF","sourcesContent":[".settings {\n  display: flex;\n  flex-direction: column;\n  gap: 20px;\n  align-items: stretch;\n}\n\n.settingsLine {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 20px;\n\n  @media screen and (max-width: 1000px) {\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    gap: 20px;\n  }\n}\n\n.tabs {\n  display: inline-flex;\n  flex-wrap: wrap;\n  align-items: center;\n  column-gap: 10px;\n  row-gap: 20px;\n}\n\n.tab {\n  cursor: pointer;\n  font-weight: 700;\n  border: var(--border);\n  box-shadow: var(--box-shadow-small);\n  text-transform: uppercase;\n  padding: 5px 10px;\n\n  &:hover {\n    background-color: var(--primary-color)!important;\n    color: var(--button-text-color)!important;\n  }\n\n  &:is(:focus, .active) {\n    color: var(--color-white) !important;\n    background: var(--primary-color) !important;\n  }\n}\n\n.sortFilterBlock {\n  display: flex;\n  align-items: center;\n  gap: 20px;\n}\n\n.availableFilter {\n  font-weight: 700;\n  font-size: 14px;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n\n  & input {\n    accent-color: var(--primary-color);\n    width: 20px;\n    height: 20px;\n    border-radius: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `styled_settings__hzr4Q`,
	"settingsLine": `styled_settingsLine__TqgLM`,
	"tabs": `styled_tabs__O8Yjc`,
	"tab": `styled_tab__XrzzQ`,
	"active": `styled_active__fKXuP`,
	"sortFilterBlock": `styled_sortFilterBlock__73pSS`,
	"availableFilter": `styled_availableFilter__pqhub`
};
export default ___CSS_LOADER_EXPORT___;

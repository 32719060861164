import BeforeHeader from "src/components/header/desktop/BeforeHeader";
import cn from "classnames";
import Logo from "src/components/shared/logo";
import { HEADER_LINKS } from "src/components/header/vars";
import s from "../styled.module.css";
const DesktopHeader = () => {
  return (
    <header>
      <BeforeHeader />
      <div className={cn("container", s.mainHeader)}>
        <div className={s.headerLinks}>
          <Logo />

          {Object.entries(HEADER_LINKS).map(([link, title], _) => (
            <a href={link} key={link} className={cn(s.headerLink, "primaryButton", "minimal")}>
              {title}
            </a>
          ))}
          <span className={s.divider} />
        </div>

        <button
          className={cn("primaryButton", s.loginButton, s.desktopLoginButton)}
          onClick={() => {
            window.location.href = "https://clients.anqor.is/login";
          }}
        >
          Login
        </button>
      </div>
    </header>
  );
};

export default DesktopHeader;

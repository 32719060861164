// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  border: 0;
}

article, aside, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1.5;
}

button {
  background: none;
  outline: none;
}

span {
  font-weight: inherit;
  font-size: inherit;
}

a {
  text-decoration: none;
  text-transform: initial;
  color: inherit;
}

input {
  outline: none;
  border: none;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
`, "",{"version":3,"sources":["webpack://./src/styles/reset.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,SAAS;AACX;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;AACnB","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  border: 0;\n}\n\narticle, aside, footer, header, hgroup, menu, nav, section {\n  display: block;\n}\n\nbody {\n  line-height: 1.5;\n}\n\nbutton {\n  background: none;\n  outline: none;\n}\n\nspan {\n  font-weight: inherit;\n  font-size: inherit;\n}\n\na {\n  text-decoration: none;\n  text-transform: initial;\n  color: inherit;\n}\n\ninput {\n  outline: none;\n  border: none;\n}\n\nol, ul {\n  list-style: none;\n}\n\ntable {\n  border-collapse: collapse;\n  border-spacing: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_footer__CQibS {
  background: var(--footer-background-color);

  @media screen and (max-width: 950px) {
    display: none;
  }
}

.styled_footerLink__2K5cB {
  color: var(--footer-color);

  &:hover {
    color: var(--footer-hover-color);
  }
}

.styled_footerContainer__0c9-c {
  padding-top: 30px !important;
  padding-bottom: 50px !important;
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.styled_contactIcons__8sOh9 {
  margin-top: 15px;
  display: flex;
  gap: 20px;
  align-items: center;
}

.styled_linksBlock__7uEg- {
  display: flex;
  max-width: 800px;
  flex: 1 1;
  justify-content: space-between;
}

.styled_community__OmXju, .styled_resources__azfOj {
  flex: 1 1;

  & ul {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 3px;
    max-height: 100px;
    flex: 1 1;
    gap: 10px;
  }
}

.styled_resources__azfOj {
  & ul {
    max-height: 150px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/styled.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;;EAE1C;IACE,aAAa;EACf;AACF;;AAEA;EACE,0BAA0B;;EAE1B;IACE,gCAAgC;EAClC;AACF;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;EAC/B,aAAa;EACb,SAAS;EACT,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,SAAS;EACT,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,SAAO;EACP,8BAA8B;AAChC;;AAEA;EACE,SAAO;;EAEP;IACE,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,SAAO;IACP,SAAS;EACX;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".footer {\n  background: var(--footer-background-color);\n\n  @media screen and (max-width: 950px) {\n    display: none;\n  }\n}\n\n.footerLink {\n  color: var(--footer-color);\n\n  &:hover {\n    color: var(--footer-hover-color);\n  }\n}\n\n.footerContainer {\n  padding-top: 30px !important;\n  padding-bottom: 50px !important;\n  display: flex;\n  gap: 50px;\n  justify-content: space-between;\n}\n\n.contactIcons {\n  margin-top: 15px;\n  display: flex;\n  gap: 20px;\n  align-items: center;\n}\n\n.linksBlock {\n  display: flex;\n  max-width: 800px;\n  flex: 1;\n  justify-content: space-between;\n}\n\n.community, .resources {\n  flex: 1;\n\n  & ul {\n    margin-top: 10px;\n    display: flex;\n    flex-direction: column;\n    flex-wrap: wrap;\n    row-gap: 3px;\n    max-height: 100px;\n    flex: 1;\n    gap: 10px;\n  }\n}\n\n.resources {\n  & ul {\n    max-height: 150px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `styled_footer__CQibS`,
	"footerLink": `styled_footerLink__2K5cB`,
	"footerContainer": `styled_footerContainer__0c9-c`,
	"contactIcons": `styled_contactIcons__8sOh9`,
	"linksBlock": `styled_linksBlock__7uEg-`,
	"community": `styled_community__OmXju`,
	"resources": `styled_resources__azfOj`
};
export default ___CSS_LOADER_EXPORT___;

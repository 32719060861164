import Logo from "src/components/shared/logo";
import s from "./MyModal.module.css";

const AboutUs = () => (
  <div className={s.inside}>
    <div>
      <div className={s.wrapper}>
        <h3 className={s.title}>Welcome to</h3>
        <Logo />
      </div>
      <br />
      <p>Anqor is a service dedicated to delivering exceptional SEO link-building services, offering rapid delivery of premium links within 48 working hours. We ensure diversity and quality with our backlink domains by sourcing links from real, reputable websites from several upstream providers.</p>
      <br />
      <div id="express-seo">
        <h2>Express SEO Solutions</h2>
        <ul>
          <li>
            <strong>48-Hour Completion:</strong> Our team is committed to enhancing your SEO
            performance by providing premium links with an unparalleled turnaround time of 48
            working hours, ensuring that your digital marketing strategies are implemented promptly
            and effectively.
          </li>
          <li>
            <strong>Quality Assurance:</strong> Despite the rapid delivery, rest assured that the
            quality of our links is never compromised, adhering strictly to the best SEO practices
            and protocols.
          </li>
        </ul>
      </div>

      <div id="multilingual-expertise">
        <h2>Multilingual and Multi-TLD Expertise</h2>
        <ul>
          <li>
            <strong>Versatile Offerings:</strong> Our services are designed to provide powerful
            links across a varied spectrum of languages and TLDs, enhancing your brand’s global
            visibility and reach.
          </li>
          <li>
            <strong>Targeted Impact:</strong> Our links are not just multilingual but also
            meticulously crafted to cater to specific niches and markets, ensuring that your brand
            connects meaningfully with diverse and relevant audiences, and that the juice will flow
            contextually.
          </li>
        </ul>
      </div>

      <div id="why-choose-us">
        <h2>Why Choose Anqor.is?</h2>
        <ul>
          <li>
            <strong>Robust Network:</strong> Our expansive network spans numerous domains and niches
            to provide you with links that are not just high-quality but also relevant and
            strategic.
          </li>
          <li>
            <strong>Client-Centric Approach:</strong> We prioritize your needs and objectives,
            crafting our strategies to align perfectly with your goals, ensuring outcomes that are
            not just satisfactory but also strategically advantageous.
          </li>
        </ul>
      </div>

      <div id="meet-the-team">
        <h2>The Team</h2>
        <p>
          The professionals at Anqor bring together a confluence of SEO expertise, digital marketing
          acumen, and a dedicated approach to each project. With a wealth of experience and a
          dynamic skill set, our team ensures that your links are strategically positioned for
          continuous growth and success.
        </p>
      </div>

      <div>
        <b>Engage with Precision and Professionalism</b>
        <p>
          At Anqor.is, we transcend conventional SEO practices, providing you with solutions that
          are timely, relevant, and specifically tailored to propel your brand.
        </p>
        <p>Embrace an era of strategic, rapid, and global linking solutions with Anqor.</p>
      </div>
    </div>
  </div>
);

export default AboutUs;

/* eslint-disable react/prop-types */
/* 
import {ReactComponent as Twitter} from 'src/assets/icons/twitter.svg'
import {ReactComponent as Telegram} from 'src/assets/icons/telegram.svg'
*/
import Logo from "src/assets/images/anqor-white.png";
import cn from "classnames";
import { type FC } from "react";
import { type HandleModalType } from "../../types";
import s from "./styled.module.css";

/*
const ICON_LINKS = {
  '/#': Twitter,
  '/': Telegram,
}

const COMMUNITY_LINKS = {
  '/#': 'Twitter',
  '/': 'Telegram',

}

*/
const Footer: FC<HandleModalType> = ({
  openAboutUsModal,
  openTermsModal,
  openPrivacyModal,
  openSubmitYourWebsiteModal,
  openFaqModal
}) => {
  return (
    <div className={s.footer}>
      <div className={cn("container", s.footerContainer)}>
        <div className={s.logoContacts}>
          <img src={Logo} alt="logo" width={150} />
          <h4 className={s.footerLink}>Anqor.is © 2024</h4>
          {/*
          <ul className={s.contactIcons}>
            {Object.entries(ICON_LINKS).map(([link, Icon]) => (
              <li key={link}>
                <a href={link}>
                  <Icon width={18} height={18}/>
                </a>
              </li>
            ))}     
          </ul>
          */}
        </div>

        <div className={s.linksBlock}>
          <div className={s.community}>
            <h3 className={s.footerLink}>Policies</h3>

            <ul>
              <li>
                <button onClick={openTermsModal} className={s.footerLink}>Terms</button>
              </li>
              <li>
                <button onClick={openPrivacyModal} className={s.footerLink}>Privacy Policy</button>
              </li>
            </ul>
            {/*
            <h3>Community</h3>

            <ul>
              {
                Object.entries(COMMUNITY_LINKS).map(([link, text]) => (
                  <li key={link}>
                    <a href={link}>
                      {text}
                    </a>
                  </li>
                ))
              }
            </ul>
          */}
          </div>

          <div className={s.resources}>
            <h3 className={s.footerLink}>Resources</h3>

            <ul>
              <li>
                <button onClick={openAboutUsModal} className={s.footerLink}>About</button>
              </li>
              <li>
                <button onClick={openFaqModal} className={s.footerLink}>FAQ</button>
              </li>
              <li>
                <button onClick={openSubmitYourWebsiteModal} className={s.footerLink}>Submit your website</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

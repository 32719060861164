import Logo from "src/components/shared/logo";
import s from "./MyModal.module.css";

const Updates = ({ onClose }: { onClose: () => void }) => (
  <div className={s.inside}>
    <div>
      <div className={s.wrapper}>
        <Logo />
      </div>
      <br />
      <section>
      <h3 className={s.title}>Updates:</h3>
      <br />
        <div className={s.updateContent}> 
        <p>
          * We are pleased to announce the launch of the service - 
          <img src="https://anqor.is/port.png" alt="Portugal Flag" style={{ width: '25px', height: '25px', marginRight: '0px' }} />
          <img src="https://anqor.is/dr-10.png" alt="DR 10" style={{ width: '25px', height: '25px', marginLeft: '-10px' }} />
          <strong> Portuguese Basic</strong> designed to enhance your link-building efforts in Brazil, Portugal, and other Portuguese-based locations.
        </p>

        <p>
          * The 
          <img src="https://anqor.is/de.png" alt="German Flag" style={{ width: '25px', height: '25px', marginRight: '0px' }} />
          <img src="https://anqor.is/dr-35.png" alt="DR 35" style={{ width: '25px', height: '25px', marginLeft: '-10px' }} />
          <strong>German Gem</strong> service is currently out of stock and will be available again shortly. Please stay tuned for future updates regarding its return.
        </p>

        </div>
      </section>

    </div>
  </div>
);

export default Updates;

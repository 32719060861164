import { type FC, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as BurgerIcon } from "src/assets/icons/burger.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/close.svg";
import { ReactComponent as MoonIcon } from "src/assets/icons/moon.svg";
import { ReactComponent as SunIcon } from "src/assets/icons/sun.svg";
import cn from "classnames";
import {
  BEFORE_HEADER_ITEMS,
  HEADER_LINKS,
  LANGUAGES,
  POLICIES_LINKS,
  HELP_RESOURCES_LINKS
} from "src/components/header/vars";
import Selector from "src/components/shared/selector";
import Disclosure from "src/components/shared/disclosure";
import Logo from "src/components/shared/logo";
import { useTheme } from "src/theme";
import headerStyles from "../../styled.module.css";
import { type HandleModalType } from "../../../../types";
import s from "./styled.module.css";

const BurgerMenu: FC<HandleModalType> = ({
  openAboutUsModal,
  openTermsModal,
  openPrivacyModal,
  openSubmitYourWebsiteModal,
  openFaqModal
}) => {
  const [language, setLanguage] = useState("English");
  const { isDarkTheme, toggleTheme } = useTheme();

  const handlePolicyClick = (key: string) => {
    switch (key) {
      case "terms":
        openTermsModal();
        break;
      case "privacyPolicy":
        openPrivacyModal();
        break;
      case "about":
        openAboutUsModal();
        break;
      case "faq":
        openFaqModal();
        break;
      case "submitYourWebsite":
        openSubmitYourWebsiteModal();
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Popover as="div" className={s.popover}>
        {({ open, close }) => (
          <>
            <Popover.Button className={s.burgerBtn}>
              <BurgerIcon />
            </Popover.Button>
            <Popover.Overlay className={s.overlay} />
            <Transition
              show={open}
              enter={s.enter}
              enterFrom={s.enterFrom}
              enterTo={s.enterTo}
              leave={s.leave}
              leaveFrom={s.leaveFrom}
              leaveTo={s.leaveTo}
            >
              <Popover.Panel static className={cn(s.popoverPanel)}>
                <div className={s.popoverPanel__header}>
                  <Logo />
                  <button onClick={close}>
                    <CloseIcon className={s.icon} />
                  </button>
                </div>

                <div className={s.popoverPanel__content}>
                  <ul>
                    {Object.entries(HEADER_LINKS).map(([link, title], _) => (
                      <li key={link}>
                        <a href={link} className={cn(s.burgerLink)}>
                          {title}
                        </a>
                      </li>
                    ))}

                    <li>
                      <button className={cn(s.themeButton, s.burgerLink)} onClick={toggleTheme}>
                        <span>Mode</span>
                        {isDarkTheme ? (
                          <MoonIcon width={20} height={18} className={s.icon} />
                        ) : (
                          <SunIcon width={20} height={18} />
                        )}
                      </button>
                    </li>

                    <li>
                      <Selector
                        selectedItem={language}
                        setSelectedItem={setLanguage}
                        items={LANGUAGES}
                        variant="staticPopover"
                        hideSelectedOption
                      />
                    </li>
                    <li className={s.tab}>
                      <Disclosure title="Policies" className={s.tabTitle}>
                        <ul>
                          {Object.entries(POLICIES_LINKS).map(([key, title]) => (
                            <li key={key}>
                              <button
                                onClick={() => {
                                  handlePolicyClick(key);
                                  close();
                                }}
                                className={cn(s.burgerLink, s.disclosureLink)}
                              >
                                {title}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </Disclosure>
                    </li>
                    <li>
                      <Disclosure title="Resources" className={s.tabTitle}>
                        <ul>
                          {Object.entries(HELP_RESOURCES_LINKS).map(([key, title]) => (
                            <li key={key}>
                              <button
                                onClick={() => {
                                  handlePolicyClick(key);
                                  close();
                                }}
                                className={cn(s.burgerLink, s.disclosureLink)}
                              >
                                {title}
                              </button>
                            </li>
                          ))}
                        </ul>
                      </Disclosure>
                    </li>
                  </ul>

                  <span className={s.dividerHorizontal} />

                  <ul>
                    {Object.entries(BEFORE_HEADER_ITEMS).map(([key, value]) => (
                      <li key={key} className={s.beforeHeaderItem}>
                        <p>{key}</p>
                        <p className={s.beforeHeaderItem__number}>{value}</p>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className={s.popoverPanel__footer}>
                  <button className={cn("primaryButton", headerStyles.loginButton)}>Login</button>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default BurgerMenu;

import { type Dispatch, type FC, type SetStateAction, useRef } from "react";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrowDown.svg";
import { Listbox } from "@headlessui/react";
import cn from "classnames";
import s from "./styled.module.css";

type MobileFilterProps = {
  tagFilter?: string;
  setTagFilter: Dispatch<SetStateAction<string | undefined>>;
  tabs: string[];
};

const MobileFilter: FC<MobileFilterProps> = (props) => {
  const { tagFilter, setTagFilter, tabs } = props;

  const refToClose = useRef<any>();

  const onClose = () => {
    refToClose.current.click();
  };

  return (
    <Listbox value={tagFilter} onChange={setTagFilter} defaultValue={tagFilter}>
      <div className={cn(s.listbox)}>
        <Listbox.Button
          as="button"
          className={({ open }) => cn("primaryButton", s.listboxButton, { [s.open]: open })}
        >
          <span ref={refToClose}>{tagFilter || "All"}</span>

          <ArrowIcon />
        </Listbox.Button>
        <Listbox.Options className={cn(s.listboxPaper, "paper")}>
          <div className={s.listboxPaper__header}>
            <p className={s.listboxHeading}>Filter by</p>
            <button className={s.closeBtn} onClick={onClose}>
              &#x2715;
            </button>
          </div>

          <ul className={s.listboxOptions}>
            <li className={s.optionContainer}>
              <Listbox.Option value="">
                <label className={s.filterOption}>
                  <input
                    type="radio"
                    name="filter"
                    checked={tagFilter === undefined}
                    value={undefined}
                  />
                  All
                </label>
              </Listbox.Option>
            </li>

            {tabs.map((tab) => (
              <li key={tab} className={s.optionContainer}>
                <Listbox.Option value={tab}>
                  <label className={s.filterOption}>
                    <input type="radio" name="filter" checked={tagFilter === tab} value={tab} />

                    {tab}
                  </label>
                </Listbox.Option>
              </li>
            ))}
          </ul>
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default MobileFilter;

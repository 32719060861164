// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_logo__rLaUu {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 0 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/shared/logo/styled.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,cAAc;AAChB","sourcesContent":[".logo {\n  display: flex;\n  align-items: center;\n  gap: 15px;\n  padding: 0 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `styled_logo__rLaUu`
};
export default ___CSS_LOADER_EXPORT___;

import { Disclosure as DisclosureHUI } from "@headlessui/react";
import { type FC, type ReactNode } from "react";
import cn from "classnames";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrowDown.svg";
import s from "./styled.module.css";

type DisclosureProps = {
  title: string;
  isNormalFontWeight?: boolean;
  children: ReactNode;
  className?: string;
};

const Disclosure: FC<DisclosureProps> = (props) => {
  const { title, isNormalFontWeight, children, className } = props;

  return (
    <DisclosureHUI>
      {({ open }) => (
        <>
          <DisclosureHUI.Button
            as="button"
            className={cn(
              "primaryButton",
              "minimal",
              s.disclosureButton,
              {
                [s.open]: open,
                "font-weight-normal": isNormalFontWeight
              },
              className
            )}
          >
            <span>{title}</span>
            <ArrowIcon />
          </DisclosureHUI.Button>
          <DisclosureHUI.Panel className={cn(s.disclosurePanel, { [s.open]: open })}>
            {children}
          </DisclosureHUI.Panel>
        </>
      )}
    </DisclosureHUI>
  );
};

export default Disclosure;

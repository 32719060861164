import {type ChangeEvent, type FC, useEffect, useState} from "react";
import {type Table as TableTanType} from "@tanstack/table-core/build/lib/types";
import {type ServiceType} from "src/types";
import cn from "classnames";
import SortSelector from "src/components/main/sort-selector";
import MobileFilter from "src/components/main/mobile-filter";
import useWindowSize from "src/hooks/useWindowSize";
import Search from "../search";
import s from './styled.module.css'

const TABS = ['English', 'German', 'dr', 'rd', 'traffic', 'dr10+', 'dr20+', 'homepage']

const MOBILE_WIDTH = 450

type SettingsProps = {
  table: TableTanType<ServiceType>;
  globalFilter: string;
}

const Settings: FC<SettingsProps> = (props) => {
  const {table, globalFilter} = props;

  const {width} = useWindowSize()
  const [tagFilter, setTagFilter] = useState<string>()
  const {setGlobalFilter, setColumnFilters} = table

  useEffect(() => {
    if (tagFilter) {
      setColumnFilters(prev =>
        [...prev, {id: 'tags', value: tagFilter}]
      )
    } else {
      setColumnFilters((prev) => prev.filter(({id}) => id !== 'tags'))
    }
  }, [tagFilter]);

  const onAvailableFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setColumnFilters(prev => {
      if (e.target.checked) {
        return [...prev, {id: 'available', value: true}]
      } else {
        return prev.filter(({id}) => id !== 'available')
      }
    })
  }

  const renderFilterAvailability = () => (
    <label className={s.availableFilter}>
      <input type="checkbox" onChange={onAvailableFilterChange}/>
      Show only available
    </label>
  )

  return (
    <div className={s.settings}>
      <Search
        onChange={(e) => setGlobalFilter(
          String(e.target.value)
        )}
        value={globalFilter}
        placeholder="Search by name, terms, price or stats (all websites are real, no PBN's)"
      />

      <div className={s.settingsLine}>
        {
          width < 450 && renderFilterAvailability()
        }

        {width >= MOBILE_WIDTH &&
          (<ul className={s.tabs}>
            <li>
              <label className={cn('primaryButton', s.tab, {[s.active]: !tagFilter})}>
                all {!tagFilter && table.getRowModel().rows.length}
                <input
                  name="tags"
                  type="radio"
                  onChange={(e) =>
                    e.target.checked && setTagFilter('')}
                  hidden
                />
              </label>
            </li>

            {TABS.map((tab) => (
              <li key={tab}>
                <label className={cn('primaryButton', s.tab, {[s.active]: tagFilter === tab})}>
                  {tab} {tagFilter === tab && table.getRowModel().rows.length}
                  <input
                    name="tags"
                    type="radio"
                    onChange={(e) =>
                      e.target.checked && setTagFilter(tab)}
                    hidden
                  />
                </label>
              </li>
            ))}
          </ul>)
        }

        <div className={s.sortFilterBlock}>
          {width < MOBILE_WIDTH && (
            <MobileFilter tagFilter={tagFilter} setTagFilter={setTagFilter} tabs={TABS}/>
          )}

          <SortSelector table={table}/>

          {
            width >= 450 && renderFilterAvailability()
          }
        </div>
      </div>
    </div>
  );
};

export default Settings;

import MobileRowAccordion from "src/components/main/row-list/mobile-row-accordion";
import {type FC} from "react";
import {type Row} from "@tanstack/react-table";
import {type ServiceType} from "src/types";
import s from './styled.module.css'

type RowListProps = {
  rows: Array<Row<ServiceType>>
}

const RowList: FC<RowListProps> = (props) => {
  const {rows} = props

  return (
    <ul className={s.list}>
      {
        rows.map(row => (
          <li key={row.id}>
            <MobileRowAccordion row={row}/>
          </li>
        ))
      }
    </ul>
  );
};

export default RowList;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_list__yodv7 {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  & li {
    border-bottom: var(--border);

    &:first-of-type {
      border-top: var(--border);
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/main/row-list/styled.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oBAAoB;;EAEpB;IACE,4BAA4B;;IAE5B;MACE,yBAAyB;IAC3B;EACF;AACF","sourcesContent":[".list {\n  display: flex;\n  flex-direction: column;\n  align-items: stretch;\n\n  & li {\n    border-bottom: var(--border);\n\n    &:first-of-type {\n      border-top: var(--border);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": `styled_list__yodv7`
};
export default ___CSS_LOADER_EXPORT___;

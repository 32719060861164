import s from "src/components/main/styled.module.css";
import CopyChip from "src/components/shared/copy-chip";
import { createColumnHelper } from "@tanstack/react-table";
import { type HighlightType, type ServiceType } from "src/types";
import { ReactComponent as InfoIcon } from "src/assets/icons/info.svg";
import Tooltip from "src/components/shared/tooltip";
import { tagsFilter } from "src/components/main/filters";

const columnHelper = createColumnHelper<ServiceType>();

export const TagsIconsCell = ({ tags }: { tags: HighlightType[] }) => (
  <div className={s.tagImages}>
    {tags && Array.isArray(tags)
      ? [...tags] 
          .sort((a, b) => a.position - b.position) 
          .map(
            ({ name, icon_image }) =>
              !!icon_image && (
                <img
                  src={icon_image}
                  key={name}
                  width={35}
                  height={35}
                  className={s.tagImage}
                  alt={name}
                />
              )
          )
      : null}
  </div>
);

export const columns = [
  columnHelper.accessor("tags", {
    header: "",
    filterFn: tagsFilter,
    cell: (cellContext) => <TagsIconsCell tags={cellContext.row.original.tags} />
  }),
  columnHelper.accessor("name", {
    header: "",
    cell: (name) => (
      <div className={s.mainHeadCell}>
        <div className={s.mainHeadCell__header}>
          {name.row.original.highlights &&
          Array.isArray(name.row.original.highlights) &&
          name.row.original.highlights.sort
            ? name.row.original.highlights
                .sort((a, b) => a.position - b.position)
                .map(({ name, description }) => (
                  <Tooltip
                    key={name}
                    text={description}
                    position="bottom"
                    tooltipClassName={s.highliightTooltip}
                  >
                    <span className={s.highlight}>{name}</span>
                  </Tooltip>
                ))
            : null}
        </div>
        <div className={s.mainHeadCell__main}>
          <CopyChip
            title={name.getValue()}
            text={name.row.original.description}
            contentToCopy={name.row.original.link}
            stopPropagationItem="copyButton"
          />
        </div>
        <div className={s.mainHeadCell__footer}>
          {name.row.original.tags
            .sort((a, b) => a.position - b.position)
            .map(({ name, description }) => (
              <CopyChip key={name} text={description} title={name} contentToCopy={name} />
            ))}
        </div>
      </div>
    )
  }),
  columnHelper.accessor("terms", {
    header: () => (
      <div className={s.thWithTooltip}>
        <span>Terms</span>
        <Tooltip text="Conditions for the specific service" tooltipClassName={s.tooltip}>
          <InfoIcon className={s.infoIcon} />
        </Tooltip>
      </div>
    ),
    cell: (terms) => (
      <Tooltip
        text={terms.row.original.terms_expanded}
        position="bottom"
        tooltipClassName={s.termsTooltip}
      >
        <p className={s.terms} dangerouslySetInnerHTML={{ __html: terms.renderValue() ?? "" }} />
      </Tooltip>
    )
  }),
  columnHelper.accessor("supply", {
    header: () => (
      <div className={s.thWithTooltip}>
        <span>Supply</span>
        <Tooltip text="Represents how much supply is left from 1-10" tooltipClassName={s.tooltip}>
          <InfoIcon className={s.infoIcon} />
        </Tooltip>
      </div>
    )
  }),
  columnHelper.accessor("price", {
    header: "Price",
    cell: (price) => <b>${price.renderValue()}</b>
  }),
  columnHelper.accessor("dr", {
    header: "DR",
    sortUndefined: -1
  }),
  columnHelper.accessor("rd", {
    header: "RD",
    sortUndefined: -1
  }),
  columnHelper.accessor("available", {
    header: "Available"
  })
];

import cn from "classnames";
import {
  type ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable
} from "@tanstack/react-table";
import {useEffect, useState} from "react";
import {type ServiceType} from "src/types";
import useWindowSize from "src/hooks/useWindowSize";
import RowList from "src/components/main/row-list";
import Table from "./table";
import s from './styled.module.css'
import {columns} from "./table-columns";
import Settings from "./settings";

const Main = () => {
  const [data, setData] = useState<ServiceType[]>([])
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const {width} = useWindowSize()


  const table = useReactTable({
      data,
      columns,
      state: {
        sorting, columnFilters,
        globalFilter,
        columnVisibility: {
          'available': false,
        }
      },

      onSortingChange: setSorting,
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,

      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
    }
  );

  useEffect(() => {
    void (async function fetchData() {
      try {
        const res = await fetch('https://api.anqor.is/api/v1/services')
        const {services} = await res.json()
        setData(services)
      } catch (e) {
        console.error(e)
      }
    })();
  }, []);

  return (
    <main className={cn(s.container, s.main)}>
      <div className={s.content}>
        <div className={s.contentContainer}>
          <Settings table={table} globalFilter={globalFilter}/>
        </div>

        {
          width > 850 ? (
              <div className={s.contentContainer}>
                <Table table={table}/>
              </div>
            ) :
            <RowList rows={table.getRowModel().rows}/>
        }
      </div>
    </main>
  );
};

export default Main;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styled_inputContainer__BO3Tg {
  font-size: 18px;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
  border: var(--border);

  &:has(.styled_input__qwgU7:focus) {
    outline: var(--border-primary);
  }
}

.styled_input__qwgU7 {
  align-self: stretch;
  width: 100%;
  background-color: inherit;
  color: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/components/main/search/styled.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,iBAAiB;EACjB,qBAAqB;;EAErB;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".inputContainer {\n  font-size: 18px;\n  height: 50px;\n  display: flex;\n  align-items: center;\n  gap: 10px;\n  padding: 5px 10px;\n  border: var(--border);\n\n  &:has(.input:focus) {\n    outline: var(--border-primary);\n  }\n}\n\n.input {\n  align-self: stretch;\n  width: 100%;\n  background-color: inherit;\n  color: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `styled_inputContainer__BO3Tg`,
	"input": `styled_input__qwgU7`
};
export default ___CSS_LOADER_EXPORT___;

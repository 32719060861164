import { ReactComponent as CopyIcon } from "src/assets/icons/copy.svg";
import { type FC, type MouseEvent } from "react";
import cn from "classnames";
import s from "./styled.module.css";

type CopyChipProps = {
  title: string;
  text?: string;
  contentToCopy: string;
  titleClassName?: string;
  stopPropagationItem?: "copyButton" | "chip";
};

const CopyChip: FC<CopyChipProps> = (props) => {
  const { title, titleClassName, text, contentToCopy, stopPropagationItem = "chip" } = props;

  const onCopyToClipboard = (e: MouseEvent) => {
    e.stopPropagation();
    navigator.clipboard.writeText(contentToCopy).then();
  };

  return (
    <span
      className={cn(s.container, {
        "cursor-pointer": stopPropagationItem === "copyButton"
      })}
      onClick={(e) => stopPropagationItem === "chip" && e.stopPropagation()}
    >
      <span className={cn(s.title, titleClassName)}>{title}</span>
      <span className={s.additionalBlock}>
        {text && <span className={s.text} dangerouslySetInnerHTML={{ __html: text }} />}
        <button className={s.copyButton} onClick={onCopyToClipboard}>
          <CopyIcon />
        </button>
      </span>
    </span>
  );
};

export default CopyChip;

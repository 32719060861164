import {type FC, Fragment, type ReactNode, useRef} from "react";
import {Popover, Transition} from "@headlessui/react";
import cn from "classnames";
import s from './styled.module.css'

type TooltipProps = {
  text: string;
  children: ReactNode;
  position?: 'top' | 'bottom';
  tooltipClassName?: string;
}

const Tooltip: FC<TooltipProps> = (props) => {
  const {text, children, tooltipClassName, position = 'top'} = props;

  const triggerRef = useRef<any>()

  const handleEnter = (isOpen: boolean) => {
    !isOpen && triggerRef.current?.click()
  }

  const handleLeave = (isOpen: boolean) => {
    isOpen && triggerRef.current?.click()
  }

  return (
    <Popover className={cn('relative', 'w-content')}>
      {({open}) => (
        <div
          onMouseEnter={() => handleEnter(open)}
          onMouseLeave={() => handleLeave(open)}
          className="w-content"
        >
          <Popover.Button ref={triggerRef}>
            {children}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter={s.enter}
            enterFrom={s.enterFrom}
            enterTo={s.enterTo}
            leave={s.leave}
            leaveFrom={s.leaveFrom}
            leaveTo={s.leaveTo}
          >
            <Popover.Panel className={cn(s.tooltip, {[s[position]]: true, [tooltipClassName ?? '']: true})}>
              {text}
            </Popover.Panel>
          </Transition>
        </div>
      )
      }
    </Popover>
  )
};

export default Tooltip;

import {flexRender, type Table as TableTanType} from "@tanstack/react-table";
import {type FC} from "react";
import {type ServiceType} from "src/types";
import {ReactComponent as ArrowDown} from "src/assets/icons/arrowLongDown.svg";
import s from 'src/components/main/table/styled.module.css'

type TableProps = {
  table: TableTanType<ServiceType>;
}

const Table: FC<TableProps> = (props) => {
  const {table} = props;

  const openServicePage = () => {
    window.location.href = 'https://clients.anqor.is/portal/services';
  }

  return (
    <div className="p-2">
      <table>
        <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id}>
                <div
                  className={s.thContainer}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                  {{
                    asc: <ArrowDown width={20} className='verticalMirror'/>,
                    desc: <ArrowDown width={20}/>,
                  }[header.column.getIsSorted() as string] ?? <div className={s.sortingMockSpace}/>}
                </div>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id} className={s.serviceRow} onClick={() => openServicePage()}>
            {row.getVisibleCells().map(cell => (
              <td key={cell.id}>
                {cell.getValue() != null 
                  ? flexRender(cell.column.columnDef.cell, cell.getContext())
                  : <span className={s.emptyCell}>-</span> // Placeholder for empty cells
                }
              </td>
            ))}
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

import { type FC } from "react";
import DesktopHeader from "src/components/header/desktop";
import MobileHeader from "src/components/header/mobile";
import useWindowSize from "src/hooks/useWindowSize";
import { type HandleModalType } from "../../types";

const Header: FC<HandleModalType> = ({ ...props }) => {
  const { width } = useWindowSize();
  const isMobile = width <= 950;

  return isMobile ? <MobileHeader {...props} /> : <DesktopHeader />;
};

export default Header;

import { Listbox } from "@headlessui/react";
import cn from "classnames";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrowDown.svg";
import { ReactComponent as ArrowDown } from "src/assets/icons/arrowLongDown.svg";
import { type Table as TableTanType } from "@tanstack/table-core/build/lib/types";
import { type FC, useEffect, useRef, useState } from "react";
import { type ServiceType } from "src/types";
import s from "./styled.module.css";

type SelectorProps = {
  table: TableTanType<ServiceType>;
};

const SORTABLE_FIELDS = ["name", "supply", "price", "rd", "dr"];

const SortSelector: FC<SelectorProps> = (props) => {
  const {
    table: { setSorting }
  } = props;

  const refToClose = useRef<any>();
  const [selectedItem, setSelectedItem] = useState<[string, "asc" | "desc"]>(["price", "asc"]);

  useEffect(() => {
    setSorting([
      {
        id: selectedItem[0],
        desc: selectedItem[1] === "desc"
      }
    ]);
  }, [selectedItem]);

  const onClose = () => {
    refToClose.current.click();
  };

  return (
    <Listbox value={selectedItem} onChange={setSelectedItem} defaultValue={selectedItem}>
      <div className={cn(s.listbox)}>
        <Listbox.Button
          as="button"
          className={({ open }) => cn("primaryButton", s.listboxButton, { [s.open]: open })}
        >
          <span ref={refToClose}>
            <span className={s.sortBy}>Sort by </span>
            <b className="capitalize">{selectedItem[0]}</b> ({selectedItem[1]})
          </span>
          <ArrowIcon />
        </Listbox.Button>
        <Listbox.Options className={cn(s.listboxPaper, "paper")}>
          <div className={s.listboxPaper__header}>
            <p className={s.listboxHeading}>Sort by</p>
            <button className={s.closeBtn} onClick={onClose}>
              &#x2715;
            </button>
          </div>

          <div className={s.optionsTitles}>
            <p>Asc</p>
            <p>Desc</p>
          </div>

          <ul className={s.listboxOptions}>
            {SORTABLE_FIELDS.map((item) => (
              <li key={item} className={s.optionContainer}>
                <p className={s.optionName}>{item}</p>

                <div className={s.buttonsContainer}>
                  {["asc", "desc"].map((direction) => (
                    <Listbox.Option
                      key={direction}
                      value={[item, direction]}
                      className={cn(s.listboxOption, {
                        [s.active]: selectedItem[0] === item && selectedItem[1] === direction
                      })}
                    >
                      <ArrowDown className={cn({ verticalMirror: direction === "asc" })} />
                    </Listbox.Option>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </Listbox.Options>
      </div>
    </Listbox>
  );
};

export default SortSelector;

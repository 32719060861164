import Logo from "src/components/shared/logo";
import s from "./MyModal.module.css";

const Privacy = () => (
  <div className={s.inside}>
    <div>
      <div className={s.wrapper}>
        <h3 className={s.title}>Privacy Policy</h3>
        <Logo />
      </div>

      <p>Last Updated: October 6, 2023</p>
      <p>Purpose of the Privacy Policy.</p>
      <p>
        We are committed to respecting and protecting your privacy rights, and we created this
        Privacy Policy to give you (the user) notice of how your private information will (and will
        not) be used by us in connection with our website (together with its subdomains, content and
        services). In simplified form (greater detail is below), our policy can be summarized as
        follows:
      </p>
      <p>
        1. We may collect some information which you provide.
        <br />
        2. We do not collect information from children under 13 years of age.
        <br />
        3. We take your information seriously.
        <br />
        4. We may sell or rent your personal information in connection with the products or services
        you are seeking.
        <br />
        5. We may aggregate information.
        <br />
        6. In certain legal situations, we may be compelled to disclose your personal information,
        but we will let you know if that occurs.
        <br />
        7. If you are outside the United States, you understand and agree that we may store your
        information in the United States.
        <br />
      </p>
      <p>
        By participating or accessing this website in any manner, you acknowledge that you accept
        the practices and policies outlined in this Privacy Policy as well as in the Terms of
        Service (located at the webpage: https://anqor.is), and you hereby consent that we may
        collect, use, and share your information in the following ways. This Privacy Policy may be
        updated from time to time, but the changes will not apply retroactively. We will notify you
        of any material changes by posting the new Privacy Policy on the Site. You are advised to
        consult this policy regularly for any changes.
      </p>
      <p>
        <b>1. What Information Do We Collect and How Do We Collect It?</b>
        <br />
        <br />
      </p>
      <p>
        Information collected is often defined as being either anonymous or personally identifiable.
        <br />
        <br />
        Anonymous Information
        <br />
        <br />
        Anonymous Information refers to information that cannot be tied back to a specific
        individual. For instance, we may know that thousands of people have visited this Privacy
        Policy, but we do not necessarily know their names, their address, or their date of birth.
        What we collect from visits such as this constitutes anonymous information. As is true of
        most apps and web sites, we gather certain information (such as mobile provider, operating
        system, etc.) automatically and store it in log files. We use this information, which does
        not identify individual users, to analyze trends, to administer the website, to track users
        movements around the website and to gather demographic information about our user base as a
        whole. We may link some of this automatically-collected data to certain Personally
        Identifiable Information.
        <br />
        <br />
        Personally Identifiable Information
        <br />
        <br />
        Personally Identifiable Information refers to information that tells us specifically who you
        are, such as your name and email address. At various times, you could be asked to provide
        Personally Identifiable Information such as your name and email address. Users may, of
        course, visit our website anonymously. But if you choose to leave a review on our website,
        you may be required to provide us with certain Personally Identifiable Information such as
        your name and email address. You may also choose to provide us with additional Personally
        Identifiable Information. We may also collect personally identifiable information from users
        in a variety of ways, including, but not limited to, when users visit our site, register on
        the site, place an order, and in connection with other activities, services, features or
        resources we make available on our website. If you choose to allow us to collect your
        location data, such location data will be available. In addition, if you have an account
        with Facebook, Twitter, Google+, LinkedIn, Foursquare or other similar websites (each a
        “Social Network”), your Social Network identity, including your public picture, may be
        associated with you and/or your location data.
        <br />
        <br />
        In addition, our website may use &quot;cookies&quot; log-files and other tracking
        technologies to enhance the user experience. A user&apos;s web browser places cookies on
        their hard drive for record-keeping purposes and sometimes to track information about them.
        We may link some of this information with your Personally Identifiable Information. You may
        choose to set your web browser to refuse cookies, or to alert you when cookies are being
        sent. If you do so, note that some parts of our website may not function properly. If you
        send a “Do Not Track” request through your web browser, we do not currently respond or take
        any action with respect to web browser “do not track” signals and will still collect and use
        your browsing data to improve security, to provide our website and to generate reporting
        statistics, and we will track other web sites that you may have visited. We may allow third
        parties, such as ad servers, to collect personally identifiable information about your
        online activities over time and across different websites when you use our website. anqor.is
        is the owner of the information collected through the website.
      </p>
      <p>
        No Information Collected from Children.
        <br />
        <br />
        We will never knowingly collect any personal information from children under the age of 13.
        If we obtain actual knowledge that we have collected personal information from a child under
        the age of 13, that information will be immediately removed from any access. Because we do
        not collect such information, we have no such information to use or to disclose to third
        parties. We have designed this policy in order to comply with the Children’s Online Privacy
        Protection Act (“COPPA”). Third Party Information
        <br />
        <br />
        We may collect information from you when you provide information to a third party and we
        subsequently acquire or use the information provided by the third party. Such information
        may include, but is not limited to, your name, email address, street address, zip code,
        telephone numbers (including cell phone numbers and carriers), occupation, industry of
        employment, personal and online interests, and such other information as you may have
        provided to the third party. When acquiring this information, we seek assurances from the
        third party that you agreed to provide and have such information acquired by us. If you did
        not give express permission, or you would like to remove your permission, you may suppress
        all of your information by using the unsubscribe link on the email you have received.
      </p>
      <p>
        <b>2. How Do We Use Individual Information?</b>
        <br />
        <br />
        General Use
        <br />
        <br />
        We use personal information in connection with the website and may contact you regarding
        account activities, new versions and product offerings, or other communications relevant to
        the website. Your personal information may be used: to improve our website and our services
        to you; to improve our customer service; to personalize your user experience; to be
        transferred to our subsidiaries and affiliated companies for processing and storing such
        information; share information with third parties for marketing or other purposes; or to
        help us run a promotion, contest, survey or other website feature. If you contact us by
        email or by filling-out a registration form, we may keep a record of your contact
        information and correspondence, and may use your email address, and any information that you
        provide to us in your message, to respond to you. In addition, we may use the personal
        information described above to send you information regarding the website and its
        advertisers. If you decide at any time that you no longer wish to receive such information
        or communications from us, email us at hello@anqor.is and request to be removed from our
        list. The circumstances under which we may share such information with third parties are
        described in part (4) below. Bank Information
        <br />
        <br />
        We may, in certain instances, collect credit card or banking and related information when an
        individual submits a request through us. When the credit card or banking information is
        submitted to us, such information is encrypted and is protected with SSL encryption
        software. We will use the banking information for purposes of processing and completing the
        requested transaction payment, and the information will be disclosed to third parties only
        as necessary to complete the job.
      </p>
      <p>
        <b>3. How Do We Store Your Personal Information?</b>
        <br />
        <br />
        Storage of Individual Information We operate or lease our data networks. . We cannot
        guarantee that our security measures will prevent our computers from being illegally
        accessed, and the individual information on them stolen or altered. Your account is
        protected by a password for your privacy and security. You must prevent unauthorized access
        to your account and personal information by selecting and protecting your password
        appropriately and limiting access to your computer or device and browser by signing off
        after you have finished accessing your account.
        <br />
        <br />
        Third Party Hosting, Serving and Storage
        <br />
        <br />
        WE MAY CONTRACT WITH A VARIETY OF THIRD PARTY SUPPLIERS, PROVIDERS, VENDORS, AND SOCIAL
        NETWORKS FROM TIME TO TIME, TO PROVIDE FOR OUR HOSTING, AUTHENTICATION, SERVING, STORAGE AND
        TELECOMMUNICATION NEEDS, ETC., INCLUDING WITHOUT LIMITATION STORAGE OF OUR USERS PERSONALLY
        IDENTIFIABLE INFORMATION. WE SHALL NOT BE RESPONSIBLE NOR LIABLE FOR, AND HEREBY DISCLAIM,
        ALL RESPONSIBILITY AND LIABILITY FOR CULPABLE (INCLUDING WITHOUT LIMITATION NEGLIGENT) ACTS
        OR OMISSIONS BY OUR THIRD PARTY SUPPLIERS OR VENDORS.
      </p>
      <p>
        <b>4. Do We Disseminate Any Personal Information?</b>
        <br />
        <br />
        Service Providers
        <br />
        <br />
        We may use third parties to help operate our website, authenticate users and deliver
        products and services, and may share limited Personally Identifiable Information with our
        service providers, vendors, suppliers, advertisers and other third parties that provide
        products or services for or through the website (such as website or database hosting
        companies, authentication providers, address list hosting companies, e-mail service
        providers, analytics companies, distribution companies and other similar service providers
        that use such information on our behalf).
        <br />
        Advertisers
        <br />
        <br />
        Advertisements appearing on our website may be delivered to users by advertising partners,
        who may set cookies, web beacons, clear gifs or similar technologies. Cookies allow an
        advertiser’s ad server to recognize your computer each time they send you an online
        advertisement to compile non-personally identifiable information about you and others who
        use your computer. This information allows ad networks, among other things, to deliver
        targeted advertisements that they believe might be of interest to you. This privacy policy
        does not cover our advertiser’s use of cookies. A web beacon is a programming code that can
        be used to display an image on a web page, but can also be used to transfer your unique user
        identification to a database and associate you with previously acquired information about an
        individual in a database. This allows a company to track certain web sites you visit. Web
        beacons are used to track online behavioral habits for marketing and other purposes such as
        to determine products or services you may be interested in. In addition to using web beacons
        on web pages, we may also use web beacons in email messages sent to individuals listed in
        our database.
        <br />
        Aggregate Statistics
        <br />
        <br />
        We may disclose aggregate statistics regarding user behavior as a measure of interest in,
        and use of, our website, our Apps and e-mails to third parties in the form of aggregate
        data, such as overall patterns or demographic reports that do not describe or identify any
        individual user.
        <br />
        Complying with Legal Process
        <br />
        <br />
        We take reasonable steps to ensure the privacy of data uploaded. We reserve the right to use
        or disclose your Personally Identifiable Information and other information in response to
        subpoenas, court orders, warrants, or legal process, or to otherwise establish or exercise
        our legal rights or defend against legal claims or in the event you violate or breach an
        agreement with us. We may use and disclose your Personally Identifiable Information if we
        believe you will harm the property or rights of us or others.
        <br />
        Business Transitions
        <br />
        <br />
        In the event we go through a business transition, such as a merger, acquisition by another
        company, or sale of all or a portion of our assets as well as in the event of a bankruptcy,
        assignment for benefit of creditors or receivership, your Personally Identifiable
        Information may be disclosed and will likely be among the assets transferred. You may be
        notified thereafter via prominent notice on our website for 30 days of any such change in
        ownership or control of your personal information. We further reserve the right to disclose,
        transfer or sell your personal information to companies who are affiliated with us in our
        sole discretion.
        <br />
        <br />
        Opt Out
        <br />
        <br />
        You may choose not to receive future promotional, advertising or activity-related emails
        from us by selecting an Unsubscribe link at the bottom of emails/notifications that you
        receive from us. Our unsubscribe process impacts only the future delivery of electronic
        mailings disseminated by us. You may still receive electronic mailings sent on behalf of
        Third Parties and your personal information may still be shared with Third Parties for use
        in offline marketing and data appends, including email appends. You should also note that
        unsubscribing from our electronic mailings will not automatically unsubscribe the
        subscriber’s information from any Third Party business associates and licensees of the data.
        Since Third Party associates and licensee partners maintain separate databases from ours,
        and you will need to unsubscribe from each source individually, if desired. This allows you
        the freedom to pick and choose which subscriptions to maintain and which to discontinue.
        Telemarketing We may use Personally Identifiable Information to advertise, directly or
        indirectly, to individuals using direct mail marketing or telemarketing using telephones and
        cell phones. You may have your telephone number or cell phone number listed on a state or
        federal do not call registry. By registering and using this Service, you are waiving your
        rights and privileges under these laws and expressly giving permission of Company and any
        agent of Company the right to contact you by telephone or cell phone, and you agree that
        such act constitutes an inquiry and/or application for purposes of the Amended Telemarketing
        Sales Rule (16 CFR §310 et seq.), as amended from time to time (the &quot;Rule&quot;).
        Further, if you are residing outside the United States, by registering and using this
        Service, you acknowledge that the processing of your individual information may involve the
        transfer of such Individual information from within or outside the European Economic Area
        (“EEA”) to countries within or outside the EEA whose data protection regulation may not be
        as stringent as that within the European Union. We may use individual information to provide
        the services you’ve requested, including services that display customized content and
        advertising. Wireless Addresses. If the e-mail address you provide to us is a wireless
        e-mail or text address or if you provide a phone number for text messaging, you agree to
        receive messages at such address from us or our business associates (unless and until you
        have elected not to receive such messages by following the instructions in the unsubscribe
        portion of this policy). You understand that your wireless carrier’s standard rates apply to
        these messages. You represent that you are the owner or authorized user of the wireless
        device on which messages will be received, and that you are authorized to approve the
        applicable charges. Short Message Service. We may make available a service through which you
        can receive messages on your wireless device via short message service (“SMS Service”). Data
        obtained from you in connection with this SMS service may include your name, address, cell
        phone number, your provider’s name, and the date, time, and content of your messages. You
        represent that you are 18 years of age and the owner or authorized user of the wireless
        device on which messages will be received, and that you are authorized to approve the
        applicable charges. In addition to any fee of which you are notified, your provider’s
        standard messaging rates apply to our confirmation and all subsequent SMS correspondence.
        All charges are billed by and payable to your mobile service provider. We will not be liable
        for any delays in the receipt of any SMS messages, as delivery is subject to effective
        transmission from your network operator. SMS message services are provided on an AS IS
        basis. We may also obtain the date, time and content of your messages in the course of your
        use of the SMS Service. We will use the information we obtain in connection with our SMS
        Service in accordance with this Privacy Policy. If fees are charged to your wireless account
        invoice, we may provide your carrier with your applicable information in connection
        therewith. Your wireless carrier and other service providers may also collect data about
        your wireless device usage, and their practices are governed by their own policies.
        <br />
        <br />
        Third Party Offers
        <br />
        <br />
        We may make customer lists available to companies or other entities not affiliated with us
        that have goods, services and offers that might be of interest to you. We may, from time to
        time, provide your Personally Identifiable Information to these entities so that they may
        send you these offers. Users may find advertising or other content on our website that links
        to the sites and services of our partners, suppliers, advertisers, sponsors, licensors and
        other third parties.
        <br />
        Marketing
        <br />
        We will use your Personally Identifiable Information for any marketing and survey purpose on
        our behalf and our affiliates, parent and subsidiaries to send information to you about
        additional goods or services that may be of interest to you. In addition, we may disclose
        your Personally Identifiable Information to third party agents and independent contractors
        to help us conduct our marketing and survey efforts and to share with other companies in
        connection with marketing efforts including but not limited to direct marketing. You may
        have no relationship with these other companies. Co-sponsored Offerings We may provide an
        offering which is co-sponsored by another company. In those situations, the Personally
        Identifiable Information we obtain from you in connection with such offering may be shared
        with our co-sponsor. In those situations, our co-sponsors will have the right to use your
        Personally Identifiable Information for their own purposes, in accordance with their own
        policies. If you do not wish for the information we obtain from you to be shared with our
        co-sponsor, then do not enter such contest, sweepstake or other offering or otherwise
        provide to us any Personally Identifiable Information in connection with such contest,
        sweepstake or other offering. We are not responsible for how our co-sponsors may use your
        information.
      </p>
      <p>
        <b>5. Do We Allow Foreign Use Of The Apps?</b>
        <br />
        <br />
        To the extent that you are accessing the website while domiciled outside of the United
        States, you acknowledge that the Personally Identifiable Information you are providing us
        may be collected and stored in the United States and elsewhere and therefore consent to the
        transfer of information to and storage of the information outside of your domiciled country
        and in the United States.
      </p>
      <p>
        <b>6. California User Consumer Rights.</b>
        <br />
        <br />
        In accordance with California Civil Code Section 1789.3, California resident users are
        entitled to know that they may file grievances and complaints with California Department of
        Consumer Affairs, 400 R Street, STE 1080, Sacramento, CA 95814; or by phone at 916-445-1254
        or 800-952-5210; or by email to dca@dca.ca.gov. For more information about protecting your
        privacy, you may wish to visit: http://www.ftc.gov If you are a California resident,
        California Civil Code Section 1798.83 permits you to request information regarding the
        disclosure of your personal information by us to third parties for the third parties&apos;
        direct marketing purposes. With respect to us, this privacy policy applies only to their
        activities within the State of California. To make such a request or if you have questions
        about this privacy policy, please send an email to: hello@anqor.is
      </p>
      <p>
        <b>7. If I have Questions Concerning This Privacy Policy?</b>
        <br />
        <br />
        If you have any questions or concerns about this Privacy Policy, please contact us at
        hello@anqor.is.
      </p>
    </div>
  </div>
);

export default Privacy;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  margin-top: 10px;
  background-color: var(--table-background-color);
}

th {
  cursor: pointer;

  &:hover {
    color: var(--primary-color);

    & svg {
      color: var(--primary-color);
    }
  }
}

.styled_thContainer__aHxqL {
  display: flex;
  align-items: center;
  gap: 2px;
}

tr {
  border-bottom: var(--border);
  height: 90px;
}

thead tr {
  height: 50px;
}

th {
  text-align: left;
  font-weight: normal;

  &:nth-of-type(1) {
    width: 85px;
  }

  &:nth-of-type(2) {
    width: 30%;
  }

  &:nth-of-type(3) {
    width: 40%;

    @media screen and (max-width: 1000px) {
      width: 30%;
    }
  }
}

.styled_serviceRow__HicHk {
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    background-color: var(--table-row-hover-color);
    transition: var(--transition);
  }
}

.styled_sortingMockSpace__17bRC {
  display: inline-block;
  width: 20px;
  height: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/components/main/table/styled.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,+CAA+C;AACjD;;AAEA;EACE,eAAe;;EAEf;IACE,2BAA2B;;IAE3B;MACE,2BAA2B;IAC7B;EACF;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;;EAEnB;IACE,WAAW;EACb;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;;IAEV;MACE,UAAU;IACZ;EACF;AACF;;AAEA;EACE,eAAe;EACf,6BAA6B;;EAE7B;IACE,8CAA8C;IAC9C,6BAA6B;EAC/B;AACF;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,WAAW;AACb","sourcesContent":["table {\n  width: 100%;\n  margin-top: 10px;\n  background-color: var(--table-background-color);\n}\n\nth {\n  cursor: pointer;\n\n  &:hover {\n    color: var(--primary-color);\n\n    & svg {\n      color: var(--primary-color);\n    }\n  }\n}\n\n.thContainer {\n  display: flex;\n  align-items: center;\n  gap: 2px;\n}\n\ntr {\n  border-bottom: var(--border);\n  height: 90px;\n}\n\nthead tr {\n  height: 50px;\n}\n\nth {\n  text-align: left;\n  font-weight: normal;\n\n  &:nth-of-type(1) {\n    width: 85px;\n  }\n\n  &:nth-of-type(2) {\n    width: 30%;\n  }\n\n  &:nth-of-type(3) {\n    width: 40%;\n\n    @media screen and (max-width: 1000px) {\n      width: 30%;\n    }\n  }\n}\n\n.serviceRow {\n  cursor: pointer;\n  transition: var(--transition);\n\n  &:hover {\n    background-color: var(--table-row-hover-color);\n    transition: var(--transition);\n  }\n}\n\n.sortingMockSpace {\n  display: inline-block;\n  width: 20px;\n  height: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thContainer": `styled_thContainer__aHxqL`,
	"serviceRow": `styled_serviceRow__HicHk`,
	"sortingMockSpace": `styled_sortingMockSpace__17bRC`
};
export default ___CSS_LOADER_EXPORT___;

import BurgerMenu from "src/components/header/mobile/burger-menu";
import cn from "classnames";
import { type FC } from "react";
import s from "../styled.module.css";
import { type HandleModalType } from "../../../types";
import Logo from "../../shared/logo";

const MobileHeader: FC<HandleModalType> = ({ ...props }) => {
  return (
    <header>
      <div className={cn("container", s.mobileHeaderContent)}>
        <BurgerMenu {...props} />
        <Logo />
      </div>
    </header>
  );
};

export default MobileHeader;

import React from "react";
import Logo from "src/components/shared/logo";
import s from "./MyModal.module.css";

const Faq = () => (
  <div className={s.inside}>
    <div>
      <div className={s.wrapper}>
        <h3 className={s.title}>Frequently Asked Questions (FAQs)</h3>
        <Logo />
      </div>
      <br />
      <p>
        <b>How Fast Can I Expect Results?</b>
        <br />
        Our unique selling proposition lies in our commitment to delivering premium links within an
        unprecedented timeframe of 48 working hours. This ensures your SEO and marketing campaigns
        can progress without unnecessary delays.
      </p>
      <p>
        <b>Where do the links come from?</b>
        <br />
        The links come from a variety of <b>real websites (no PBN&apos;s are involved)</b> that
        utilize our products and have agreed to our terms and conditions. These partnerships are
        essential in creating a diverse and robust backlink profile. We ensure that all links are
        placed on reputable websites that are relevant and beneficial to your SEO strategies.
        Additionally, in the event that any of these websites remove our links, we have a proactive
        process in place to replace them promptly.
      </p>
      <p>
        <b>How many links are placed on each page?</b>
        <br />
        We aim to have your link placed alone on the designated page, but it will never be placed
        with more than two other links.
      </p>
      <p>
        <b>
          If I buy multiple services. Would my link / links be placed on the same domain or are you
          able to provide different domains?
        </b>
        <br />
        Your links will be placed on different domains. Our extensive network includes thousands of
        domains, ensuring that your links will be distributed across various domains. Therefore, a
        single service does not represent a single domain and does not limit your links to just one
        domain; instead, they are spread across hundreds of unique domains.
      </p>
      <p>
        <b>Where are the homepage links added to?</b>
        <br />
        Homepage links differ from all other services in that the link is inserted on the homepage
        and shifted out of screen, rather then nich edited into an existing page content. The link
        will not be visible to the end user but will exist and be visible when checked using the
        page source.
      </p>
      <p>
        <b>
          Is the price a recurring charge, or are the links permanent, requiring only a one-time
          fee?
        </b>
        <br />
        The pricing structure for this service involves a single, upfront payment. There are no
        recurring charges. Additionally, this one-time fee includes a warranty that covers a full
        year.
      </p>
      <p>
        <b>Am I able to choose the anchor text?</b>
        <br />
        Anchor text selection is tricky with niche edits and curated backlinks. We try to provide a
        relevant anchor text according to your requests. However, most times it will be a generic
        term or a keyword related to your niche
        <br />
        With homepage links purchase, you can provide a short paragraph with the exact anchor and
        the surrounding text.
      </p>

      <p>
        <b>What Does &quot;Multilingual and Multi-TLD Expertise&quot; Mean?</b>
        <br />
        Our multilingual and multi-TLD expertise implies that we provide SEO and link-building
        services across various languages and domain extensions, ensuring your brand has a robust
        and diverse online presence, connecting with audiences in different linguistic demographics
        and regions.
      </p>

      <p>
        <b>Do you provid a report?</b>
        <br />
        Yes. We supply a report attachment for each purchase once it&apos;s complete.
      </p>

      <p>
        <b>What Does &quot;Multilingual and Multi-TLD Expertise&quot; Mean?</b>
        <br />
        Our multilingual and multi-TLD expertise implies that we provide SEO and link-building
        services across various languages and domain extensions, ensuring your brand has a robust
        and diverse online presence, connecting with audiences in different linguistic demographics
        and regions.
      </p>
      <p>
        <b>What is the Difference Between RD and DR?</b>
        <br />
        Referring Domains (RD) and Domain Rating (DR) are key metrics used in SEO analysis,
        particularly in the context of ahrefs.com tools. RD measures the number of unique domains
        that link to a website, reflecting the breadth of its backlink profile. In contrast, DR
        evaluates the overall strength of a website&apos;s backlink profile, grading it on a scale
        based on both the quantity and quality of its backlinks. While RD offers insights into the
        diversity of a domain&apos;s backlink sources, DR provides an aggregated view of its
        authority and influence in SEO terms.
      </p>
      <p>
        <b>How Do You Ensure Quality in Your Links?</b>
        <br />
        Every link provided by Anqor.is undergoes a stringent quality assurance process. Our links
        are derived from tens of thousands of unique, geniune domains, ensuring they positively
        contribute to your SEO endeavors. Moreover, we meticulously craft links to cater to specific
        markets and niches for meaningful and impactful brand connections.
      </p>
      <p>
        <b>Can I Choose Links From Specific Languages or Niches?</b>
        <br />
        Yes, our services are designed to be highly versatile and customizable. You can choose a
        service from the table to specify your preference for links from particular languages,
        ensuring the link-building aligns perfectly with your targeted SEO strategy. We will do our
        best to choose a link from a domain that matches your target page.
      </p>
      <p>
        <b>Is There a Risk of Google Penalty with Your Links?</b>
        <br />
        We adhere to stringent SEO best practices, ensuring that the links we provide are not just
        high-quality but also comply with Google&apos;s guidelines, mitigating the risk of
        penalties.
      </p>
      <p>
        <b>Can I Get a Customized Link-Building Package?</b>
        <br />
        Absolutely! At Anqor.is, we pride ourselves on offering client-centric services. Reach out
        to our team with your specific requirements, and we will curate a link-building package that
        aligns seamlessly with your unique SEO objectives and budget.
      </p>
      <p>
        <b>What Are &quot;Niche Edits&quot; and How Can They Benefit My Website?</b>
        <br />
        Niche edits, also known as curated links, involve placing your backlinks into existing, aged
        articles or posts on relevant websites within your niche. This is the service we provide and
        this method can significantly elevate your website&apos;s authority, as the link comes from
        an established page, often with its own steady traffic and SEO value.
      </p>
      <p>
        <b>How Do You Select Suitable Articles for Niche Edits?</b>
        <br />
        Our team conducts thorough research to identify articles that align with your brand and
        niche. By ensuring relevance and examining the existing content&apos;s quality and traffic,
        we select articles that not only facilitate effective link placement but also potential
        referral traffic and increased visibility within your target market.
      </p>
      <p>
        <b>Can Niche Edits Be Implemented in Any Niche or Industry?</b>
        <br />
        Absolutely! Our extensive network spans a multitude of niches and industries. Whether you
        operate within the technology, healthcare, fashion, finance, or any other sector, our team
        can procure and place links in relevant, high-authority articles tailored to your specific
        industry.
      </p>
      <p>
        <b>How Do You Ensure the Quality of Niche Edits?</b>
        <br />
        Quality assurance is paramount in our niche edit services. We guarantee placements in
        articles with substantial authority and relevance. Each article is scrutinized for its
        traffic, relevancy to your niche, and overall SEO value to ensure that the backlink provides
        tangible benefits to your SEO strategy.
      </p>
      <p>
        <b>Can I Review and Approve the Articles Chosen for Niche Edits?</b>
        <br />
        We handle the entire process to ensure speed and efficiency, but we value client
        involvement. If you do not like the link placement then you will be able to request a
        replacment again once.
      </p>
      <p>
        <b>How Long Will My Link Stay Active Within the Edited Niche Article?</b>
        <br />
        We aim to secure placements in articles where your link will remain active indefinitely. We
        prioritize webmasters and websites with a stable history to ensure longevity and sustained
        value from every niche edit implemented.
      </p>
      <p>
        <b>Is There a Limit to the Number of Niche Edits I Can Purchase?</b>
        <br />
        There is no stringent limit to the number of niche edits you can purchase. Whether you need
        a few specific placements or a broad, large-scale campaign, our team is equipped to
        facilitate your requirements effectively within your desired timeframe.
      </p>
      <p>
        <b>How is the Pricing Determined for Niche Edit Services?</b>
        <br />
        Pricing for niche edits is determined based on various factors, including the domain’s
        authority where the link will be placed and the niche&apos;s competitiveness. For detailed
        pricing information and to curate a package that aligns with your budget and objectives,
        kindly reach out to our team directly.
      </p>
    </div>
  </div>
);

export default Faq;
